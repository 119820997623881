import React from 'react';
import { db, storage, getProviderUserByProderviderUserDataUid } from './../../firebase';
import { Link, navigate } from '@reach/router';
// import CustomDateInput from './../../Components/CustomDateInput';
// import DatePicker from "react-datepicker";
import { Helper } from './../../Helper';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
const axios = require('axios').default;
export default class ViewProviderUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: false,
            is_loading: true,
            provider_id: props.provider_id,
            books: [],
            vaccineList: [
                "ยังไม่ได้ฉีด",
                "ฉีดแล้ว 1 เข็ม",
                "ฉีดแล้ว 2 เข็ม",
                "ฉีดแล้ว 3 เข็ม",
                "ฉีดแล้ว 4 เข็ม",
            ],
        }
    }

    componentDidMount() {
        this.fetchUser();
    }
    async fetchUser() {
        const providerUser = await getProviderUserByProderviderUserDataUid(this.state.provider_id);
        let user = {};
        let booking = null;
        if (providerUser) {
            booking = await db.collection('books').where('providerUserUid', '==', providerUser.id).get();
        }
        let _booking = [];
        if (booking) {
            _booking = await Promise.all(
                booking.docs.map(async (element) => {
                    return {
                        ...element.data(),
                        id: element.id,
                    };
                })
            );
        }
        await db.doc("provider_users_data/" + this.state.provider_id).get().then(async (doc) => {
            user = { ...doc.data() };
            if (user.traningUID) {
                await db.doc("training_date/" + user.traningUID).get().then(async (doc) => {
                    user.educateDate = doc.data() && doc.data().date ? doc.data().date : new Date().getTime();
                })
            }
            if (user.tranningStatus) {
                await db.doc("training_date_status/" + user.tranningStatus).get().then(async (doc) => {
                    user.tranningStatusData = { ...doc.data() };
                })
            }
        })
        let bans = [];
        let banRef = await db.collection(`provider_users_data`).doc(this.state.provider_id).collection(`banHistory`).orderBy('created', 'desc').get();
        if (!banRef.empty) {
            for (const banDoc of banRef.docs) {
                let ban_doc_data = { ...banDoc.data() }
                ban_doc_data.id = banDoc.id;
                bans.push(ban_doc_data)
            }
        }
        user.ban = bans;
        let provider_user = {};
        if (providerUser) {
            provider_user = await db.doc("provider_users/" + providerUser.id).get();
        }
        let provider_user_data = {};
        if (provider_user.exists) {
            provider_user_data = provider_user.data();
        }
        if (user?.agentUid) {
            let agentRef = await db.collection('agents').doc(user.agentUid).get();
            if (agentRef.exists) {
                user.agent = agentRef.data();
            }
        }
        console.log(user);
        let _user = { ...user, provider_user: provider_user_data };
        if (user.birthDay) {
            _user.birthDay = Helper.getDateToEdit(user.birthDay);
        }
        if (user.educateDate) {
            _user.educateDate = Helper.getDateToEdit(user.educateDate);
        }
        if (user.transferDate) {
            _user.transferDate = Helper.getDateToEdit(user.transferDate);
        }


        let training_date = [];
        await db.collection("training_date").orderBy('date', 'asc').get().then(async (doc) => {
            let data = [];
            data = await Promise.all(
                doc.docs.map(async (element) => {
                    return { ...element.data(), date: new Date(element.data().date), id: element.id };
                })
            );
            training_date = data;
        })
        let training_date_status = await db.collection('training_date_status').get();
        let _training_date_status = await Promise.all(
            training_date_status.docs.map(async (element) => {
                let element_data = { ...element.data() };
                return {
                    ...element_data,
                    id: element.id,
                };

            })
        );
        this.setState({
            training_date: training_date,
            training_date_status: _training_date_status,
            user: _user,
            books: _booking,
            is_loading: false,
        })
    }

    async updateStatus(data) {
        await db.doc("provider_users_data/" + this.state.provider_id).update({ ...data }).then(() => {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                body: 'เปลี่ยนสถานะสำเร็จ',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.fetchUser();
        })
    }

    async delete(data, buttonNode) {

        const participants = await db.collection(`training_date/${data.traningUID}/participants`).get();
        participants.docs.map(async (doc) => {
            if (doc.data().providerUid === this.state.provider_id) {
                await db.doc(`training_date/${data.traningUID}/participants/` + doc.id).delete();
            }
        })
        const booking = await db.collection('books').where('providerUserUid', '==', this.state.provider_id).get();
        let documentRef = db.doc(`provider_users_data/` + this.state.provider_id);
        if (booking.docs.length > 0) {
            axios.delete(window.api_host + '/admin-user-delete?' + window.$.param({
                uid: this.state.provider_id,
            })).then(async (res) => {
                if (res.data.code === 200) {
                    this.fetchUser();
                    window.$(document).Toasts('create', {
                        title: 'ยกเลิกแม่บ้านสำเร็จ',
                        body: 'ทำการยกเลิกแม่บ้านเรียบร้อยแล้ว',
                        icon: 'fas fa-check',
                        autohide: true,
                        delay: 3000,
                        class: "bg-success",
                    })
                }
            }).catch(error => {
                window.$(document).Toasts('create', {
                    title: 'เกิดข้อผิดพลาด',
                    body: 'กรุณาลองใหม่อีกครั้ง',
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
                buttonNode.removeClass('loading');
            });
        }
        if (booking.docs.length === 0) {
            const getUser = await db.collection('users').where('phoneNumber', '==', data.phoneNumber).get();
            if (getUser.docs.length > 0) {
                this.removeProvider(data, documentRef);
            } else {
                axios.post(window.api_host + '/deleteAuth', {
                    phoneNumber: data.phoneNumber.replace('+66', '66'),
                    whyDelete: 'ineedtodelete',
                }).then(async (res) => {
                    if (res.data.code === 200) {
                        this.removeProvider(data, documentRef);
                    }
                }).catch(error => {
                    window.$(document).Toasts('create', {
                        title: 'เกิดข้อผิดพลาด',
                        body: 'กรุณาลองใหม่อีกครั้ง',
                        icon: 'fas fa-times',
                        autohide: true,
                        delay: 3000,
                        class: "bg-danger",
                    })
                    buttonNode.removeClass('loading');
                });

            }

        }
    }

    removeProvider(data, documentRef) {
        if (data.transferImageUrl) {
            storage.ref(data.transferImageUrl).delete();
        }
        if (data.profileImageUrl) {
            storage.ref(data.profileImageUrl).delete();
        }
        if (data.IDcardImageUrl) {
            storage.ref(data.IDcardImageUrl).delete();
        }
        if (data.AddressCardImageUrl) {
            storage.ref(data.AddressCardImageUrl).delete();
        }
        if (data.bankAccountImageUrl) {
            storage.ref(data.bankAccountImageUrl).delete();
        }
        documentRef.delete().then(() => {
            navigate('/provider-users')
            window.$(document).Toasts('create', {
                title: 'ยกเลิกแม่บ้านสำเร็จ',
                body: 'ทำการยกเลิกแม่บ้านเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
        });
    }

    async uploadFile(node, options) {
        let id = this.state.provider_id;
        let upload_btn = node.parent().find('.btn');
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        const filename = Helper.setNewImageFileName(file);
        let reszieFile = file;
        if (options.resize) {
            reszieFile = await Helper.resizeImageFn(file)
        }
        var uploadTask = storage.ref().child('provider_users_data/' + id + '/' + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log(snapshot);
        }, (error) => {
            console.log(error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            upload_btn.removeClass('loading');
        }, async () => {
            let url = 'provider_users_data/' + id + '/' + filename;
            if (options.onSuccess) {
                options.onSuccess(url);
            }

        });
    }


    render() {
        let registerBy = false;
        if (this.state.user.registerBy && this.state.user.registerBy === 'admin') {
            registerBy = "สมัครโดยผู้ดูแลระบบ"
        }
        if (this.state.user.registerBy && this.state.user.registerBy === 'yourself') {
            registerBy = "สมัครด้วยตัวเอง"
        }
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ข้อมูลแม่บ้าน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/maids">แม่บ้าน</Link></li>
                                    <li className="breadcrumb-item active">ดูข้อมูล</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"content"}>
                    <div className="container-fluid" >
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.user ?
                                <>
                                    <div className={"row"}>
                                        <div className="col-lg-4">
                                            <div className="card" style={{ position: 'relative', zIndex: 100 }}>
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h3 className="card-title">
                                                        {this.state.user.prefix} {this.state.user.firstName} {this.state.user.lastName}
                                                    </h3>
                                                </div>
                                                <div className="card-body" >
                                                    <div className="row">
                                                        <div className="col-sm-12 col-lg-6">
                                                            <a data-fancybox href={this.state.user.profileImage ? this.state.user.profileImage : "/assets/images/no_image.png"}>
                                                                <img src={this.state.user.profileImage ? this.state.user.profileImage : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                            </a>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-6">
                                                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <div className="row">
                                                                        <label>รายได้ปัจจุบัน</label>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            <div className={"beeclean-block"}>
                                                                                {
                                                                                    this.state.user.provider_user
                                                                                        && this.state.user.provider_user.wallet ?
                                                                                        Helper.numberFormat(this.state.user.provider_user.wallet)
                                                                                        : 0
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" to={'/rating-provider/' + this.state.provider_id}>
                                                                        <label>คะแนนรีวิว</label>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            <div className={"beeclean-block"}>
                                                                                {
                                                                                    this.state.user.provider_user
                                                                                        && this.state.user.provider_user.starFloat !== undefined ?
                                                                                        this.state.user.provider_user.starFloat.toFixed(2)
                                                                                        : '5.00'
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <Link to={"/books?providerUID=" + this.state.user.provider_user.uid} className="row">
                                                                        <label>จำนวน Booking</label>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            <div className={"beeclean-block"}>
                                                                                {Helper.numberFormat(this.state.books.length)}
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                                <div style={{ marginBottom: '0.5rem' }}>
                                                                    <div><label style={{ marginBottom: 0 }}>สถานะ APP</label></div>
                                                                    <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                                        <div>
                                                                            {
                                                                                this.state.user.status === 'waitForApprove' ?
                                                                                    <> <i className={"fas fa-circle"} style={{ color: '#ffa200' }} /> รอการยืนยัน</>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                this.state.user.status === 'approved' ?
                                                                                    <> <i className={"fas fa-circle"} style={{ color: 'blue' }} /> ยืนยันแล้ว</>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                this.state.user.status === 'avaliable' ?
                                                                                    <> <i className={"fas fa-circle"} style={{ color: 'green' }} /> ใช้งานได้</>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                this.state.user.status === 'ban' ?
                                                                                    <> <i className={"fas fa-circle"} style={{ color: 'red' }} /> ระงับการใช้งาน</>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                this.state.user.status === 'cancel' ?
                                                                                    <> <i className={"fas fa-ban"} style={{ color: 'red' }} /> ยกเลิก</>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="divider" />
                                                                <div style={{ marginBottom: '0.5rem' }}>
                                                                    <div><label style={{ marginBottom: 0 }}>สถานะการอบรม</label></div>
                                                                    <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                                        <div>{this.state.user.tranningStatusData ? this.state.user.tranningStatusData.status_name : 'ไม่มีสถานะ'}</div>
                                                                        {/* <div style={{ marginLeft: 'auto' }}>
                                                                            <div className="dropdown">
                                                                                <button className="btn btn-default btn-xs dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    <i className="far fa-edit"></i>
                                                                                </button>
                                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                    {
                                                                                        this.state.training_date_status.map((training_date_status, training_date_status_i) => {
                                                                                            if (training_date_status.id === this.state.user.tranningStatus) { return null }
                                                                                            return (
                                                                                                <div className="dropdown-item"
                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                    key={training_date_status_i}
                                                                                                    onClick={() => {
                                                                                                        let update = { tranningStatus: training_date_status.id }
                                                                                                        if (this.state.user.updateBy) {
                                                                                                            update.updateBy = [...this.state.user.updateBy];
                                                                                                        }
                                                                                                        update = Helper.providerUpdateData(update, this.props.admin, { updateType: 'tranningStatus' })
                                                                                                        this.updateStatus(update);
                                                                                                    }}
                                                                                                >
                                                                                                    {training_date_status.status_name}
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                </div><div className="divider" />
                                                                <div style={{ marginBottom: '0.5rem' }}>
                                                                    <div><label style={{ marginBottom: 0 }}>สถานะการฉีดวัคซีน</label></div>
                                                                    <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                                        <div>{this.state.user && this.state.user['covid-19'] ? this.state.user['covid-19'] : 'ไม่มีสถานะ'}</div>
                                                                        {/* <div style={{ marginLeft: 'auto' }}>
                                                                            <div className="dropdown">
                                                                                <button className="btn btn-default btn-xs dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    <i className="far fa-edit"></i>
                                                                                </button>
                                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                    {
                                                                                        this.state.vaccineList.map((vaccine, vac_i) => {
                                                                                            if (this.state.user['covid-19'] && vaccine == this.state.user['covid-19']) { return null }
                                                                                            return (
                                                                                                <div className="dropdown-item"
                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                    key={vac_i}
                                                                                                    onClick={() => {
                                                                                                        let update = {};
                                                                                                        update['covid-19'] = vaccine;
                                                                                                        if (this.state.user.updateBy) {
                                                                                                            update.updateBy = [...this.state.user.updateBy];
                                                                                                        }
                                                                                                        update = Helper.providerUpdateData(update, this.props.admin, { updateType: 'vaccineStatus' })
                                                                                                        this.updateStatus(update);
                                                                                                    }}
                                                                                                >
                                                                                                    {vaccine}
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="divider"></div>
                                                    <div className="flex row" style={{ marginTop: 5 }}>
                                                        <div>
                                                            {
                                                                registerBy ?
                                                                    <label style={{ marginBottom: 0 }}>{registerBy}</label>
                                                                    : null
                                                            }
                                                            <div>{this.state.user.createdAt ? Helper.getDateThai(this.state.user.createdAt, { is_show_time: true }) : null}</div>
                                                            {
                                                                this.state.user.registerAdmin ?
                                                                    <div>
                                                                        {this.state.user.registerAdmin.adminName}
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card collapsed-card">
                                                <div className="card-header">
                                                    <h3 className="card-title">พื้นที่ให้บริการ {" "}
                                                        {/* <Link to={"/provider-users/provider-service-area/" + this.state.provider_id}>แก้ไขข้อมูล</Link> */}
                                                    </h3>
                                                    <div className="card-tools">
                                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                            <i className="fas fa-plus"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-body" style={{ display: 'none' }}>
                                                    {
                                                        Helper.showProvinceWithAmphoe(this.state.user.provincesCode, this.state.user.amphoesCode).length > 0 ?
                                                            <>
                                                                {
                                                                    Helper.showProvinceWithAmphoe(this.state.user.provincesCode, this.state.user.amphoesCode).map((province, province_i) => {
                                                                        return (
                                                                            <React.Fragment key={province_i}>
                                                                                {
                                                                                    province_i > 0 ?
                                                                                        <div className={"divider"} />
                                                                                        : null
                                                                                }
                                                                                <label>{province.province}</label>
                                                                                {
                                                                                    province.amphoes.length === 0 ?
                                                                                        <div>ไม่ได้มีการเลือก เขต/อำเภอ</div>
                                                                                        : null
                                                                                }
                                                                                <ul className="tag-label" style={{ paddingLeft: 0, marginBottom: '0.5rem' }}>
                                                                                    {
                                                                                        province.amphoes.map((amphoe, amphoe_i) => {
                                                                                            return (
                                                                                                <div key={amphoe_i}>
                                                                                                    <div>{amphoe.amphoe}</div>
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </ul>

                                                                            </React.Fragment>
                                                                        );
                                                                    })
                                                                }
                                                            </>
                                                            : null
                                                    }

                                                </div>
                                            </div>
                                            <div className="card collapsed-card">
                                                <div className="card-header">
                                                    <h3 className="card-title">ข้อมูลติดต่อ</h3>
                                                    <div className="card-tools">
                                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                            <i className="fas fa-plus"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-body" style={{ display: 'none' }}>
                                                    <div>
                                                        <label htmlFor="phoneNumber">เบอร์โทรศัพท์</label> : {this.state.user.phoneNumber.replace('+66', '0')}
                                                    </div>
                                                    <div>
                                                        <label htmlFor="lineID">Line ID</label> : {this.state.user.lineID}
                                                    </div>
                                                    <div>
                                                        <label htmlFor="emergencyContactNumber">เบอร์ติดต่อฉุกเฉิน</label> : {this.state.user.emergencyContactNumber.replace('+66', '0')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card collapsed-card">
                                                <div className="card-header">
                                                    <h3 className="card-title">หมายเหตุ</h3>
                                                    <div className="card-tools">
                                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                            <i className="fas fa-plus"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-body" style={{ display: 'none', paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                                    {
                                                        this.state.user.providerRemark
                                                            && this.state.user.providerRemark.length > 0 ?
                                                            <ul className="nav flex-column">
                                                                {
                                                                    this.state.user.providerRemark.map((providerRemark, providerRemark_i) => {
                                                                        return (
                                                                            <li className="nav-item" key={providerRemark_i} style={{ padding: '.5rem 1.25rem' }}>
                                                                                <div className={"flex row"}>
                                                                                    <div>
                                                                                        <strong>{providerRemark.remark}</strong><br />
                                                                                        {Helper.getDateThai(providerRemark.createAt)}
                                                                                    </div>
                                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                                        {providerRemark.adminName}
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        );
                                                                    })
                                                                }
                                                            </ul>
                                                            : null
                                                    }
                                                    {/* <div className="flex" style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                        <button className="btn btn-default"
                                                            onClick={() => {
                                                                Helper.reactToDom(window.$('body'),
                                                                    <RemarkForm
                                                                        user={this.state.user}
                                                                        admin={this.props.admin}
                                                                        onSuccess={() => {
                                                                            this.fetchUser()
                                                                        }}
                                                                        userUID={this.props.provider_id} />
                                                                );
                                                            }}
                                                        >
                                                            เพิ่มหมายเหตุ
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </div>
                                            {
                                                this.state.user.ban && this.state.user.ban.length > 0 ?
                                                    <div className={"card collapsed-card"}>
                                                        <div className="card-header">
                                                            <h3 className="card-title">ข้อมูลระงับการใช้งาน</h3>
                                                            <div className="card-tools">
                                                                {/* <button type="button"
                                                                    className={this.state.is_loading ? "btn btn-default btn-sm loading" : "btn btn-default btn-sm"}
                                                                    onClick={() => {
                                                                        if (this.state.user.ban.length <= 0) { return false }
                                                                        this._export.save(this.state.user.ban);
                                                                    }}
                                                                    disabled={this.state.user.ban.length <= 0}
                                                                >
                                                                    <i className="far fa-file-excel"></i> ดาวน์โหลดรายงาน
                                                                </button> */}
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body p-0" style={{ display: 'none' }}>
                                                            <ul className="nav flex-column">
                                                                {
                                                                    this.state.user.ban.map((ban, ban_i) => {
                                                                        return (
                                                                            <li className="nav-item" key={ban_i} style={{ padding: '.5rem 1.25rem' }}>
                                                                                <div className={"flex row"}>
                                                                                    <div>
                                                                                        <strong>{ban.remark}</strong>
                                                                                    </div>
                                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                                        {ban.days} วัน
                                                                                    </div>
                                                                                </div>
                                                                                <div className={"flex row"}>
                                                                                    <div>
                                                                                        {
                                                                                            ban.createdText ?
                                                                                                <div>
                                                                                                    <strong>ตั้งแต่วันที่</strong> {ban.createdText}
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            ban.endAtText ?
                                                                                                <div>
                                                                                                    <strong>ถึงวันที่</strong> {ban.endAtText}
                                                                                                </div>
                                                                                                : null
                                                                                        }
                                                                                    </div>
                                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                                        {ban.adminName}
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            <ExcelExport
                                                                data={this.state.user.ban}
                                                                ref={exporter => this._export = exporter}
                                                                fileName={"ข้อมูลระงับการใช้งาน" + Helper.getDateThai(new Date().getTime())}
                                                            >
                                                                <ExcelExportColumn field="remark" title="หมายเหตุ" width={200} />
                                                                <ExcelExportColumn field="days" title="จำนวนวัน" width={130} />
                                                                <ExcelExportColumn field="createdText" title="วันที่แบน" width={150} />
                                                                <ExcelExportColumn field="adminName" title="ผู้ดำเนินการ" width={150} />
                                                            </ExcelExport>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">ข้อมูลแม่บ้าน</h3>
                                                </div>
                                                <div className="card-body" >
                                                    <div >
                                                        <label htmlFor="personalNo">เลขบัตรประชาชน</label> : {this.state.user.personalNo}
                                                    </div>
                                                    <div >
                                                        <label>คำนำหน้านาม</label> : {this.state.user.prefix}
                                                    </div>

                                                    <div>
                                                        <label htmlFor="firstName">ชื่อ</label> : {this.state.user.firstName}
                                                    </div>

                                                    <div >
                                                        <label htmlFor="lastName">นามสกุล</label> : {this.state.user.lastName}
                                                    </div>
                                                    <div >
                                                        <label htmlFor="gender">เพศ</label> : {this.state.user.gender}
                                                    </div>
                                                    <div >
                                                        <label htmlFor="birthDay">วันเดือนปีเกิด</label> : {Helper.getDateThai(this.state.user.birthDay)}
                                                    </div>
                                                    <div>
                                                        <label htmlFor="currentOccupation">อาชีพ</label> : {this.state.user.currentOccupation}
                                                    </div>
                                                    <div>
                                                        <label htmlFor="currentWorkplace">สถานที่ทำงาน</label> : {this.state.user.currentWorkplace}
                                                    </div>
                                                    <div>
                                                        <label>โรคประจำตัว</label> : {this.state.user.iscongenitalDisease === 'ไม่มี' ? '-' : this.state.user.congenitalDisease}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header flex row">
                                                    <h3 className="card-title">ข้อมูลการอบรม</h3>
                                                    {/* {
                                                        this.state.user.status !== 'cancel' ?
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                <div className={"link"}
                                                                    onClick={() => {
                                                                        Helper.reactToDom(
                                                                            window.$('body'),
                                                                            <ProviderTranningDateForm
                                                                                training_date={this.state.training_date}
                                                                                user={this.state.user}
                                                                                onUpdateSuccess={() => {
                                                                                    this.fetchUser();
                                                                                }}
                                                                                provider_id={this.state.provider_id}
                                                                            />
                                                                        )
                                                                    }}>
                                                                    แก้ไขข้อมูล
                                                                </div>
                                                            </div>
                                                            : null
                                                    } */}
                                                </div>
                                                <div className="card-body" >

                                                    <div>
                                                        <label htmlFor="province">วันที่อบรม</label> :  {this.state.user.educateDate ? Helper.getDateThai(this.state.user.educateDate) : '-'}
                                                    </div>
                                                    <div>
                                                        <label>สลิป</label>
                                                        {
                                                            this.state.user.transferImage ?
                                                                <div>
                                                                    <img src={this.state.user.transferImage} alt="slip"
                                                                        style={{ maxWidth: '100%', marginBottom: 15 }}
                                                                    />
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                    <div>
                                                        <label>จำนวนเงิน</label> :  {this.state.user.amountTransferBySlip ? Helper.numberFormat(this.state.user.amountTransferBySlip) : 0}
                                                    </div>
                                                    <div>
                                                        <label>วันที่โอน</label> : {this.state.user.transferDate ? Helper.getDateThai(this.state.user.transferDate) : '-'} {this.state.user.transferTime ? " - " + this.state.user.transferTime : null}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-lg-4">
                                            {/* {
                                                this.state.user.status !== 'cancel' ? <button type="button"
                                                    className="btn btn-danger form-group"
                                                    style={{ width: '100%' }}
                                                    ref={(ref) => {
                                                        window.$(ref).off().on('click', () => {
                                                            if (window.$(ref).hasClass('loading')) { return; }
                                                            window.$(ref).addClass('loading');
                                                            Helper.confirmModal({
                                                                title: "ยืนยันยกเลิกแม่บ้าน",
                                                                description: "ต้องการยกเลิกแม่บ้านท่านนี้หรือไม่ ?",
                                                                onConfirm: () => {
                                                                    window.$(ref).removeClass('loading');
                                                                    this.delete(this.state.user, window.$(ref));
                                                                },
                                                                onCancel: () => {
                                                                    window.$(ref).removeClass('loading');
                                                                },
                                                                submit_text: 'ยกเลิกแม่บ้าน',
                                                                cancle_text: 'ไม่ดำเนินการ'
                                                            });
                                                        })
                                                    }
                                                    }
                                                >ยกเลิกแม่บ้าน</button>
                                                    : null
                                            } */}
                                            <div className="card">
                                                <div className="card-header flex row">
                                                    <h3 className="card-title">ข้อมูลธนาคาร</h3>
                                                    {/* {
                                                        this.state.user.status !== 'cancel' ?
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                <div className={"link"}
                                                                    onClick={() => {
                                                                        Helper.reactToDom(
                                                                            window.$('body'),
                                                                            <BankForm
                                                                                provider_id={this.state.provider_id}
                                                                                user={this.state.user}
                                                                                onSuccess={() => {
                                                                                    this.fetchUser();
                                                                                }}
                                                                            />
                                                                        )
                                                                    }}
                                                                >
                                                                    แก้ไขข้อมูล
                                                                </div>
                                                            </div>
                                                            : null
                                                    } */}
                                                </div>
                                                <div className="card-body" >
                                                    {
                                                        !this.state.user.bank ?
                                                            <div style={{ textAlign: 'center' }}>
                                                                ไม่มีข้อมูล
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.user.bank ?
                                                            <>
                                                                <div>
                                                                    <label>ธนาคาร</label> :  {this.state.user.bank}
                                                                </div>
                                                                <div>
                                                                    <label>สาขา</label> :  {this.state.user.bankBranch}
                                                                </div>
                                                                <div>
                                                                    <label>ชื่อบัญชี</label> :  {this.state.user.bankAccountName}
                                                                </div>
                                                                <div>
                                                                    <label>เลขบัญชี</label> :  {this.state.user.bankAccountNo}
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header flex row">
                                                    <h3 className="card-title">ข้อมูลเอกสาร</h3>
                                                </div>
                                                <div className="card-body" >
                                                    <div className={"row"}>
                                                        <div className={"col-sm-12 col-lg-6"}>
                                                            <div>
                                                                {
                                                                    !this.state.user.IDcardImage ?
                                                                        <i className="far fa-times-circle" style={{ color: 'red', fontSize: 20 }}></i>
                                                                        :
                                                                        <i className="far fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>
                                                                } <label>สำเนาบัตรประชาชน</label>
                                                            </div>
                                                            <a data-fancybox href={this.state.user.IDcardImage ? this.state.user.IDcardImage : "/assets/images/no_image.png"}>
                                                                <img src={this.state.user.IDcardImage ? this.state.user.IDcardImage : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                            </a>
                                                            {/* {
                                                                this.state.user.status !== 'cancel' ?
                                                                    <div style={{ marginTop: 15 }}>
                                                                        <input name="IDcardImage"
                                                                            type={"file"}
                                                                            ref={(ref) => this.$IDcardImage = window.$(ref)}
                                                                            style={{ display: 'none' }}
                                                                            id="file_image_idcard"
                                                                            onChange={(e) => {
                                                                                if (!this.$IDcardImage[0] || !this.$IDcardImage[0].files[0]) { return false; }
                                                                                let upload_btn = this.$IDcardImage.parent().find('.btn');
                                                                                this.uploadFile(this.$IDcardImage, {
                                                                                    onSuccess: async (url) => {
                                                                                        let IDcardImageUrl = url;
                                                                                        let IDcardImage = await storage.ref(url).getDownloadURL();
                                                                                        if (this.state.user.IDcardImageUrl) {
                                                                                            storage.ref(this.state.user.IDcardImageUrl).delete();
                                                                                        }
                                                                                        db.doc(`provider_users_data/` + this.state.provider_id).update({
                                                                                            IDcardImage: IDcardImage,
                                                                                            IDcardImageUrl: IDcardImageUrl
                                                                                        }).then(res => {
                                                                                            this.fetchUser();
                                                                                            upload_btn.removeClass('loading');
                                                                                        });
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                        <label htmlFor="file_image_idcard" className="btn btn-default" style={{ width: '100%' }}>
                                                                            อัพโหลดเอกสาร
                                                                        </label>
                                                                    </div>
                                                                    : null
                                                            } */}
                                                        </div>
                                                        <div className={"col-sm-12 col-lg-6"}>
                                                            <div>
                                                                {
                                                                    !this.state.user.bankAccountImage ?
                                                                        <i className="far fa-times-circle" style={{ color: 'red', fontSize: 20 }}></i>
                                                                        :
                                                                        <i className="far fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>
                                                                } <label>สำเนาหน้าสมุดบัญชีธนาคาร</label>
                                                            </div>
                                                            <a data-fancybox href={this.state.user.bankAccountImage ? this.state.user.bankAccountImage : "/assets/images/no_image.png"}>
                                                                <img src={this.state.user.bankAccountImage ? this.state.user.bankAccountImage : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                            </a>
                                                            {/* {
                                                                this.state.user.status !== 'cancel' ?
                                                                    <div style={{ marginTop: 15 }}>
                                                                        <input name="bankAccountImage"
                                                                            type={"file"}
                                                                            ref={(ref) => this.$bankAccountImage = window.$(ref)}
                                                                            style={{ display: 'none' }}
                                                                            id="file_image_bank"
                                                                            onChange={(e) => {
                                                                                if (!this.$bankAccountImage[0] || !this.$bankAccountImage[0].files[0]) { return false; }
                                                                                let upload_btn = this.$bankAccountImage.parent().find('.btn');
                                                                                this.uploadFile(this.$bankAccountImage, {
                                                                                    onSuccess: async (url) => {
                                                                                        let bankAccountImageUrl = url;
                                                                                        let bankAccountImage = await storage.ref(url).getDownloadURL();

                                                                                        if (this.state.user.bankAccountImageUrl) {
                                                                                            storage.ref(this.state.user.bankAccountImageUrl).delete();
                                                                                        }
                                                                                        db.doc(`provider_users_data/` + this.state.provider_id).update({
                                                                                            bankAccountImage: bankAccountImage,
                                                                                            bankAccountImageUrl: bankAccountImageUrl
                                                                                        }).then(res => {
                                                                                            this.fetchUser();
                                                                                            upload_btn.removeClass('loading');
                                                                                        });
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                        <div id="show_image" />
                                                                        <label htmlFor="file_image_bank" className="btn btn-default" style={{ width: '100%' }}>
                                                                            อัพโหลดเอกสาร
                                                                        </label>
                                                                    </div>
                                                                    : null
                                                            } */}
                                                        </div>
                                                        <div className={"col-sm-12 col-lg-6"}>
                                                            <div>
                                                                {
                                                                    !this.state.user.AddressCardImage ?
                                                                        <i className="far fa-times-circle" style={{ color: 'red', fontSize: 20 }}></i>
                                                                        :
                                                                        <i className="far fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>
                                                                } <label>สำเนาทะเบียนบ้าน</label>
                                                            </div>
                                                            <a data-fancybox href={this.state.user.AddressCardImage ? this.state.user.AddressCardImage : "/assets/images/no_image.png"}>
                                                                <img src={this.state.user.AddressCardImage ? this.state.user.AddressCardImage : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                            </a>
                                                            {/* {
                                                                this.state.user.status !== 'cancel' ?
                                                                    <div style={{ marginTop: 15 }}>
                                                                        <input name="AddressCardImage"
                                                                            type={"file"}
                                                                            ref={(ref) => this.$AddressCardImage = window.$(ref)}
                                                                            style={{ display: 'none' }}
                                                                            id="file_image_addresscard"
                                                                            onChange={(e) => {
                                                                                if (!this.$AddressCardImage[0] || !this.$AddressCardImage[0].files[0]) { return false; }
                                                                                let upload_btn = this.$AddressCardImage.parent().find('.btn');
                                                                                this.uploadFile(this.$AddressCardImage, {
                                                                                    onSuccess: async (url) => {
                                                                                        let AddressCardImageUrl = url;
                                                                                        let AddressCardImage = await storage.ref(url).getDownloadURL();
                                                                                        if (this.state.user.AddressCardImageUrl) {
                                                                                            storage.ref(this.state.user.AddressCardImageUrl).delete();
                                                                                        }
                                                                                        db.doc(`provider_users_data/` + this.state.provider_id).update({
                                                                                            AddressCardImage: AddressCardImage,
                                                                                            AddressCardImageUrl: AddressCardImageUrl
                                                                                        }).then(res => {
                                                                                            this.fetchUser();
                                                                                            upload_btn.removeClass('loading');
                                                                                        });
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                        <label htmlFor="file_image_addresscard" className="btn btn-default" style={{ width: '100%' }}>
                                                                            อัพโหลดเอกสาร
                                                                        </label>
                                                                    </div>
                                                                    : null
                                                            } */}
                                                        </div>
                                                        <div className={"col-sm-12 col-lg-6"}>
                                                            <div>
                                                                {
                                                                    !this.state.user.covidImage ?
                                                                        <i className="far fa-times-circle" style={{ color: 'red', fontSize: 20 }}></i>
                                                                        :
                                                                        <i className="far fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>
                                                                } <label>เอกการการฉีดวัคซีน</label>
                                                            </div>
                                                            <a data-fancybox href={this.state.user.covidImage ? this.state.user.covidImage : "/assets/images/no_image.png"}>
                                                                <img src={this.state.user.covidImage ? this.state.user.covidImage : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                            </a>
                                                            {/* {
                                                                this.state.user.status !== 'cancel' ?
                                                                    <div style={{ marginTop: 15 }}>
                                                                        <input name="covidImage"
                                                                            type={"file"}
                                                                            ref={(ref) => this.$covidImage = window.$(ref)}
                                                                            style={{ display: 'none' }}
                                                                            id="file_covid"
                                                                            onChange={(e) => {
                                                                                if (!this.$covidImage[0] || !this.$covidImage[0].files[0]) { return false; }
                                                                                let upload_btn = this.$covidImage.parent().find('.btn');
                                                                                this.uploadFile(this.$covidImage, {
                                                                                    onSuccess: async (url) => {
                                                                                        let covidImageUrl = url;
                                                                                        let covidImage = await storage.ref(url).getDownloadURL();
                                                                                        if (this.state.user.covidImageUrl) {
                                                                                            storage.ref(this.state.user.covidImageUrl).delete();
                                                                                        }
                                                                                        db.doc(`provider_users_data/` + this.state.provider_id).update({
                                                                                            covidImage: covidImage,
                                                                                            covidImageUrl: covidImageUrl
                                                                                        }).then(res => {
                                                                                            this.fetchUser();
                                                                                            upload_btn.removeClass('loading');
                                                                                        });
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                        <label htmlFor="file_covid" className="btn btn-default" style={{ width: '100%' }}>
                                                                            อัพโหลดเอกสาร
                                                                        </label>
                                                                    </div>
                                                                    : null
                                                            } */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                </div>
            </>
        );
    }
}

class ProviderTranningDateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            training_date: props.training_date || [],
            user: props.user || false,
        }
    }


    async update(data) {
        // data.traningUID
        if (this.state.user.traningUID === data.traningUID) {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.props.onUpdateSuccess();
            this.$modal.modal('hide');
            return;
        }
        const old_participants = await db.collection(`training_date/${this.state.user.traningUID}/participants`).get();
        const traningDate = await db.doc(`training_date/${data.traningUID}`).get();
        const _participants = db.collection(`training_date/${data.traningUID}/participants`);
        const participants = await _participants.get();
        const count_participants = participants.docs.length;
        //    เช็คจำนวนผู้เข้าอบรม
        if (parseFloat(traningDate.data().maxParticipants) <= parseFloat(count_participants)) {
            window.$(document).Toasts('create', {
                title: 'ผู้เข้าร่วมอบรมเต็ม',
                body: 'ผู้เข้าร่วมอบรมในวันนี้เต็มแล้ว กรุณาเลือกวันอบรมอื่น',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submit.removeClass("loading");
            return;
        }
        // ลบผู้เข้าอบรมจากวันเก่า
        old_participants.docs.map(async (doc) => {
            if (doc.data().providerUid === this.props.provider_id) {
                await db.doc(`training_date/${this.state.user.traningUID}/participants/` + doc.id).delete();
            }
        })
        // เพิ่มผู้เข้าอบรมในวันใหม่
        await _participants.add({ providerUid: this.props.provider_id });
        await db.doc("provider_users_data/" + this.props.provider_id).update({ ...data }).then(() => {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.$modal.modal('hide');
            this.props.onUpdateSuccess();
        })
    }

    render() {
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        let submit_btn = this.$submit;
                        if (submit_btn.hasClass('loading')) { return false; }
                        submit_btn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลธนาคาร</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>เลือกวันเข้าอบรม</label>
                                <select className="form-control"
                                    name={"traningUID"}
                                    defaultValue={this.state.user.traningUID}
                                    onChange={(e) => {
                                        // console.log(e.target.value);
                                    }}
                                >
                                    {
                                        this.state.training_date.map((t_date, t_date_i) => {
                                            return (
                                                <option key={t_date_i} value={t_date.id}>{Helper.getDateThai(t_date.date)}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >ยกเลิก</button>
                            <button type={"submit"} className="btn btn-primary">บันทึก</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export class BankForm extends React.Component {
    constructor() {
        super();
        this.state = {
            bank_list: [
                "ธนาคารกรุงเทพ",
                "ธนาคารกสิกรไทย",
                "ธนาคารกรุงไทย",
                "ธนาคารทหารไทย",
                "ธนาคารไทยพาณิชย์",
                "ธนาคารกรุงศรีอยุธยา",
                "ธนาคารเกียรตินาคินภัทร",
                "ธนาคารซีไอเอ็มบีไทย",
                "ธนาคารทิสโก้",
                "ธนาคารธนชาต",
                "ธนาคารยูโอบี",
                "ธนาคารไทยเครดิตเพื่อรายย่อย",
                "ธนาคารแลนด์แอนด์ เฮ้าส์",
                "ธนาคารไอซีบีซี (ไทย)",
                "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
                "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
                "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
                "ธนาคารออมสิน",
                "ธนาคารอาคารสงเคราะห์",
                "ธนาคารอิสลามแห่งประเทศไทย"
            ]
        }
    }

    async update(data) {
        await db.doc("provider_users_data/" + this.props.provider_id).update({ ...data }).then(() => {
            window.$(document).Toasts('create', {
                title: 'แก้ไขข้อมูลสำเร็จ',
                body: 'ทำการแก้ไขข้อมูลเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.$modal.modal('hide');
            this.props.onSuccess(data);
        })
    }

    render() {
        return (
            <div className="modal fade"
                id="edit_bank"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        let submit_btn = this.$submit;
                        if (submit_btn.hasClass('loading')) { return false; }
                        submit_btn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลธนาคาร</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>ธนาคาร</label>
                                <select className="form-control" name={"bank"} defaultValue={this.props.user.bank || ''} required={true}>
                                    <option value={""}>เลือกธนาคาร</option>
                                    {
                                        this.state.bank_list.map((bank, bank_i) => {
                                            return (
                                                <option key={bank_i} value={bank}>{bank}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label>สาขา</label>
                                <input name="bankBranch" defaultValue={this.props.user.bankBranch || ""} className="form-control" required={true} />
                            </div>
                            <div className="form-group">
                                <label>ชื่อบัญชี</label>
                                <input name="bankAccountName" defaultValue={this.props.user.bankAccountName || ""} className="form-control" required={true} />
                            </div>
                            <div className="form-group">
                                <label>เลขบัญชี</label>
                                <input name="bankAccountNo" defaultValue={this.props.user.bankAccountNo || ""} className="form-control" required={true} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type={"button"} className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >ยกเลิก</button>
                            <button type={"submit"} className="btn btn-primary">บันทึก</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export class BanProviderUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            start_date: new Date(),
            end_date: new Date(),
            createAt: new Date(),
        }
    }

    async update(data) {
        let updateData = { ...data };
        updateData.adminUid = this.props.admin.id.stringValue;
        updateData.providerUsersDataUid = this.props.userUID;
        try {

            let res = await axios.post(`${window.api_host}/provider-ban`, { ...updateData })
            window.$(document).Toasts('create', {
                title: 'ระงับการใช้งานแม่บ้านแล้ว',
                body: 'ระบบได้ทำการระงับการใช้งานแม่บ้านเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            let ban = [];
            if (res.data.banData) {
                ban = res.data.banData;
            }
            if (this.props.onSuccess) {
                this.props.onSuccess(ban);
            }
            this.$modal.modal('hide');
            this.$submit.removeClass('loading');
        } catch (error) {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: 'กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-exclamation',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submit.removeClass('loading');
        }
        return;
        let _data = { ...this.props.user };
        if (!_data.ban || _data.ban.length === 0) {
            _data.ban = [];
        }
        if (this.props.user.updateBy) {
            _data.updateBy = [...this.props.user.updateBy];
        }
        _data = Helper.providerUpdateData(_data, this.props.admin, { updateType: 'status', remark: data.remark, changeToStatus: 'ban', changeToStatusText: "ระงับการใช้งาน" });
        let now = new Date();
        _data.ban.push({ ...data, createdText: Helper.getDateThai(now.getTime(), { is_show_time: true }), created: now.getTime(), adminName: this.props.admin.displayName.stringValue, adminUID: this.props.admin.id.stringValue });
        _data.status = 'ban';
        db.doc("provider_users_data/" + this.props.userUID).update({ ..._data }).then(() => {
            window.$(document).Toasts('create', {
                title: 'ระงับการใช้งานแม่บ้านแล้ว',
                body: 'ระบบได้ทำการระงับการใช้งานแม่บ้าน ' + _data.prefix + ' ' + _data.firstName + ' ' + _data.lastName + ' เรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            if (this.props.onSuccess) {
                this.props.onSuccess();
            }
            this.$modal.modal('hide');
            this.$submit.removeClass('loading');
        })
    }

    render() {
        let _data = this.props.user;
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) {
                            return;
                        }
                        this.$submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        if (!data.remark || !data.days) {
                            this.$submit.removeClass('loading')
                            window.$(document).Toasts('create', {
                                title: `กรุณากรอกขอมูลให้ครบถ้วน`,
                                // body: `กรุณากรอกขอมูลให้ครบ`,
                                icon: 'fas fa-exclamation',
                                autohide: true,
                                delay: 3000,
                                class: "bg-warning",
                            })
                            return;
                        }
                        this.update(data);
                        // this.props.onSubmit(data);
                        // this.$submit.removeClass('loading')
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header" style={{ display: "flex", alignItems: 'center' }}>
                            <h4 className="modal-title" id="myModalLabel">
                                ระงับการใช้งาน
                            </h4>
                            <div style={{ marginLeft: 'auto' }}>
                                {_data.prefix} {_data.firstName} {_data.lastName}
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className={"form-group"}>
                                <label>สาเหตุ</label>
                                <textarea name={"remark"} className={"form-control"} />
                            </div>
                            <div className={"form-group"}>
                                <label>ช่วงเวลา</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" name={"days"} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">วัน</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type={"button"} className="btn btn-default"
                                onClick={() => {
                                    let $submit = this.$form.find('button[type="submit"]');
                                    $submit.removeClass('loading');
                                    this.$modal.modal('hide');
                                }}
                            >ไม่ดำเนินการ</button>
                            <button className={"btn btn-primary"} type={"submit"}>ระงับการใช้งาน</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
export class RemarkForm extends React.Component {
    update(data) {
        let _data = { ...data };
        _data.adminUID = this.props.admin.id.stringValue;
        _data.adminName = this.props.admin.displayName.stringValue;
        _data.createAt = new Date().getTime();
        _data.createAtText = Helper.getDateThai(_data.createAt);
        let remark_arr = [];
        if (this.props.user.providerRemark) {
            remark_arr = [...this.props.user.providerRemark];
        }
        remark_arr.push(_data);
        db.doc("provider_users_data/" + this.props.userUID).update({ providerRemark: remark_arr }).then(() => {
            window.$(document).Toasts('create', {
                title: 'เพิ่มหมายเหตุแม่บ้านแล้ว',
                body: 'ระบบได้ทำการเพิ่มหมายเหตุแม่บ้าน ' + _data.prefix + ' ' + _data.firstName + ' ' + _data.lastName + ' เรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            if (this.props.onSuccess) {
                this.props.onSuccess();
            }
            this.$modal.modal('hide');
            this.$submit.removeClass('loading');
        })
    }

    render() {
        let _data = this.props.user;
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) {
                            return;
                        }
                        this.$submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        this.update(data);
                        // this.props.onSubmit(data);
                        // this.$submit.removeClass('loading')
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header" style={{ display: "flex", alignItems: 'center' }}>
                            <h4 className="modal-title" id="myModalLabel">
                                เพิ่มหมายเหตุ
                            </h4>
                            <div style={{ marginLeft: 'auto' }}>
                                {_data.prefix} {_data.firstName} {_data.lastName}
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className={"form-group"}>
                                <label>หมายเหตุ</label>
                                <textarea name={"remark"} className={"form-control"} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type={"button"} className="btn btn-default"
                                onClick={() => {
                                    let $submit = this.$form.find('button[type="submit"]');
                                    $submit.removeClass('loading');
                                    this.$modal.modal('hide');
                                }}
                            >ไม่ดำเนินการ</button>
                            <button className={"btn btn-primary"} type={"submit"}>เพิ่มหมายเหตุ</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}