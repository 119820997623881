import React from 'react';
import { db } from './../firebase';
import { Helper } from './../Helper';
import { Link } from '@reach/router';
import Pagination from './../Components/Pagination';
import Chart from "react-google-charts";
import CustomDateInput from './../Components/CustomDateInput';
import DatePicker from "react-datepicker";
const axios = require('axios').default;

const today = new Date();
today.setHours(0, 0, 1);
const tomorrow = new Date()
tomorrow.setHours(23, 59, 59)
export default class Dashboard extends React.Component {
  constructor() {
    super();
    const years = [];
    let i;
    for (i = 1980; i <= new Date().getFullYear(); i++) {
      years.push(i)
    }
    this.state = {
      is_loading_provider: true,
      is_loading: true,
      providers: [],
      provider_limit: 5,
      provider_page: 1,
      provider_all_item: 0,
      all_provider: 0,
      all_booking: 0,

      is_loading_user: true,
      is_chart_loading: true,
      users: [],
      user_limit: 5,
      user_page: 1,
      user_all_item: 0,
      all_user: 0,

      booking: [["ไม่มีข้อมูล"]],

      timerage: 'รายวัน',
      years: years,
      date: new Date().getTime(),
      year: parseFloat(new Date().getFullYear()),
      month: parseFloat(new Date().getMonth()),
      day: parseFloat(new Date().getDate()),
      commissionAll: 0,
      commissionProvider: 0,
      commissionApp: 0,
      revolving_money: 0,
      income_money: 0,
      provider_money: 0,
      profit_money: 0,
      vat_money: 0,
      time: 0,
      discount_money: 0
    }
  }

  componentDidMount() {
    this.fetchProviderData();
    this.fetchBookng();
  }
  fetchProviderData() {
    try {
      db.collection("provider_users_data").where('agentUid', '==', this.props.uid).where('createdAt', "<", tomorrow.getTime()).where('createdAt', '>=', today.getTime()).orderBy('createdAt', 'desc').get().then(async (doc) => {
        let data = [];
        let prepare_data = [];
        let count = 0;
        let all_provider = 0;
        let get_data = await Promise.all(
          doc.docs.map(async (element) => {
            // all_provider++;
            return {
              ...element.data(),
              id: element.id,
            };

          })
        );
        prepare_data = get_data;
        count = prepare_data.length;
        prepare_data.forEach((__data, __data_i) => {
          if (__data_i < (this.state.provider_limit * this.state.provider_page)
            && __data_i > (((this.state.provider_limit * this.state.provider_page) - this.state.provider_limit) - 1)
          ) {
            data.push(__data)
          }
        })
        let getRef = await db.collection("provider_users_data").where('agentUid', '==', this.props.uid).get();
        all_provider = getRef.size;
        this.setState({
          providers: data,
          is_loading: false,
          is_loading_provider: false,
          provider_all_item: count,
          all_provider: all_provider,
        })
      })
    } catch (error) {
      console.log(error)
    }

  }

  async fetchBookng() {
    let qs = {}
    qs.year = this.state.year;
    qs.month = this.state.month;
    if (this.state.timerage === 'รายวัน') {
      let day = this.state.day;
      let month = parseFloat(this.state.month);
      let year = this.state.year;
      qs.startDate = new Date(year, month, day);
      qs.startDate.setHours(0, 0, 1);
      qs.endDate = new Date(qs.startDate)
      qs.endDate.setDate(qs.endDate.getDate());
      qs.endDate.setHours(23, 59, 0);
    }
    if (this.state.timerage === 'รายเดือน') {
      qs.startDate = new Date(this.state.year, parseFloat(this.state.month), 2);
      qs.startDate.setHours(0, 0, 1);
      qs.endDate = new Date(this.state.year, parseFloat(this.state.month) + 1, 2);
      qs.endDate.setHours(0, 0, 1);
    }
    if (this.state.timerage === 'รายปี') {
      qs.startDate = new Date(this.state.year, 0, 2);
      qs.startDate.setHours(0, 0, 1);
      qs.endDate = new Date(this.state.year + 1, 0, 2);
      qs.endDate.setHours(0, 0, 1);
    }
    if (qs.startDate) {
      qs.startDate = qs.startDate.getTime();
    }
    if (qs.endDate) {
      qs.endDate = qs.endDate.getTime();
    }
    if (this.state.timerage) { qs.timerage = this.state.timerage; }
    if (this.state.status) { qs.status = this.state.status; }
    if (!this.state.status || this.state.status === "") { qs.status = 'non-status'; }
    if (this.props.uid) { qs.agentUid = this.props.uid; }

    let res = await axios.get(`${window.api_host_agent}/agent-dashboard-booking?${window.$.param(qs)}`);
    // console.log(res);
    let booking = [];
    let count = 0;
    let now_count = 0;
    let pre_count = 0;
    let all_booking = 0;

    let revolving_money = 0;
    let income_money = 0;
    let provider_money = 0;
    let discount_money = 0;
    let vat_money = 0;
    let time = 0;
    let commissionAll = 0;
    let commissionProvider = 0;
    let commissionApp = 0;


    if (res.data.code === 200) {
      revolving_money = res.data.revolving_money;
      income_money = res.data.income_money;
      provider_money = res.data.provider_money;
      discount_money = res.data.discount_money;
      vat_money = res.data.vat_money;
      time = res.data.time;
      booking = res.data.booking;
      now_count = res.data.now_count;
      pre_count = res.data.pre_count;
      all_booking = res.data.all_booking;
      count = res.data.count;
      commissionAll = res.data.commissionAll;
      commissionProvider = res.data.commissionProvider;
      commissionApp = res.data.commissionApp;
    }
    this.setState({
      commissionAll: commissionAll,
      commissionProvider: commissionProvider,
      commissionApp: commissionApp,
      revolving_money: revolving_money,
      income_money: income_money,
      provider_money: provider_money,
      discount_money: discount_money,
      vat_money: vat_money,
      time: time,
      booking: booking,
      now_count: now_count,
      pre_count: pre_count,
      is_loading: false,
      is_chart_loading: false,
      all_booking: all_booking,
      booking_all_items: count,
    })
  }


  render() {
    if (this.state.is_loading) {
      return (<div style={{ padding: '1rem 0', textAlign: 'center' }}><div className="ui active inline loader" ></div></div>)
    }
    return (
      <>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">หน้าหลัก</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item active">หน้าหลัก</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className={"content"}>
          <div className="container-fluid">
            <div className={"row"}>
              {/*BOOK*/}
              <div className="col-md-12">
                <div className="card">

                  {
                    this.state.is_chart_loading ?
                      <div className={"absolute_loading"}><div className="ui active inline loader" ></div></div>
                      : null
                  }

                  <div className="card-header">
                    <h5 className="card-title">รายงาน</h5>
                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse">
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body" style={{ display: 'block' }}>
                    <div className="row">
                      <div className="col-md-9">
                        <p className="text-center">
                          <strong>
                            {
                              this.state.timerage === 'รายวัน' ?
                                <>วันที่ {Helper.getDateThai(this.state.date)}</>
                                : null
                            }
                            {
                              this.state.timerage === 'รายเดือน' ?
                                <>เดือน {window.months_th[this.state.month]}</>
                                : null
                            }
                            {
                              this.state.timerage === 'รายปี' ?
                                <>ปี {this.state.year}</>
                                : null
                            }
                          </strong>
                        </p>

                        <div className="chart">
                          <Chart
                            width={'100%'}
                            height={'300px'}
                            chartType="AreaChart"
                            loader={<div>Loading Chart</div>}
                            data={this.state.booking}
                            options={{
                              isStacked: true,
                              legend: { position: 'top', maxLines: 3 },
                              vAxis: { minValue: 0 },
                              chartArea: { width: '90%', height: '300px' },
                            }}
                            rootProps={{ 'data-testid': '2' }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className={"row form-group"}>
                          <div className="col-md-5">
                            <div style={{ width: '100%' }}>
                              <select className={"form-control form-control-sm"}
                                name={"timerage"}
                                defaultValue={this.state.timerage}
                                onChange={(e) => {
                                  this.setState({
                                    timerage: e.target.value,
                                    is_chart_loading: true,
                                  }, () => {
                                    this.fetchBookng();
                                  })
                                }}
                              >
                                <option value={"รายวัน"}>รายวัน</option>
                                <option value={"รายเดือน"}>รายเดือน</option>
                                <option value={"รายปี"}>รายปี</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-7">
                            {
                              this.state.timerage === 'รายวัน' ?
                                <div className="full-date">
                                  <DatePicker
                                    onCalendarOpen={() => { Helper.renderDate({ short_day: true }) }}
                                    onChange={date => {
                                      let _date = new Date(date)
                                      this.setState({
                                        day: _date.getDate(),
                                        month: _date.getMonth(),
                                        year: _date.getFullYear(),
                                        date: new Date(date).getTime(),
                                        is_chart_loading: true,
                                      }, () => {
                                        this.fetchBookng();
                                      })
                                    }}
                                    locale="th-TH"
                                    selected={Helper.getDateToEdit(this.state.date)}
                                    placeholderText={"วัน / เดือน / ปี"}
                                    name={"birthDay"}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    withPortal
                                    renderCustomHeader={({
                                      date,
                                      changeYear,
                                      changeMonth,
                                      decreaseMonth,
                                      increaseMonth,
                                      prevMonthButtonDisabled,
                                      nextMonthButtonDisabled
                                    }) => {
                                      let now = new Date();
                                      let selected_date = new Date(date);
                                      // months_th
                                      const years = [];
                                      let i;
                                      for (i = 1980; i <= now.getFullYear(); i++) {
                                        years.push(i)
                                      }
                                      return (
                                        <div
                                          style={{
                                            margin: 10,
                                            display: "flex",
                                            justifyContent: "center"
                                          }}
                                        >
                                          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                            className={"btn btn-primary "}
                                            type={"button"}
                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                          >
                                            <i className="fas fa-chevron-left" />
                                          </button>
                                          <select
                                            className={"form-control"}
                                            value={selected_date.getFullYear()}
                                            onChange={({ target: { value } }) => changeYear(value)}
                                            style={{ borderRadius: 0 }}
                                          >
                                            {years.map(option => (
                                              <option key={option} value={option} >
                                                {option + 543}
                                              </option>
                                            ))}
                                          </select>

                                          <select
                                            className={"form-control"}
                                            value={window.months_th[selected_date.getMonth()]}
                                            onChange={({ target: { value } }) =>
                                              changeMonth(window.months_th.indexOf(value))
                                            }
                                            style={{ borderRadius: 0 }}
                                          >
                                            {window.months_th.map(option => (
                                              <option key={option} value={option}>
                                                {option}
                                              </option>
                                            ))}
                                          </select>

                                          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                            className={"btn btn-primary"}
                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                            type={"button"}
                                          >
                                            <i className="fas fa-chevron-right" />
                                          </button>
                                        </div>
                                      )
                                    }}
                                    customInput={<CustomDateInput size={"sm"} />}
                                  />
                                  <input type="hidden" id="date" name="date" value={this.state.date} onChange={() => { console.log('change') }} readOnly={true} />
                                  <input type="hidden" id="day" name="day" value={this.state.day} onChange={() => { console.log('change') }} readOnly={true} />
                                  <input type="hidden" id="month" name="month" value={this.state.month} onChange={() => { console.log('change') }} readOnly={true} />
                                  <input type="hidden" id="year" name="year" value={this.state.year} onChange={() => { console.log('change') }} readOnly={true} />
                                </div>
                                : <input type="hidden" id="date" name="date" value={new Date().getTime()} onChange={() => { console.log('change') }} readOnly={true} />
                            }
                            <div className={this.state.timerage === 'รายเดือน' ? "flex row merge-selectbox" : "flex row"}>

                              {
                                this.state.timerage === 'รายเดือน' ?
                                  <select className={"form-control form-control-sm"}
                                    name={"month"}
                                    style={{ flex: 1 }}
                                    onChange={(e) => {
                                      this.setState({
                                        month: e.target.value,
                                        is_chart_loading: true,
                                      }, () => {
                                        this.fetchBookng();
                                      })
                                    }}
                                    defaultValue={this.state.month}
                                  >
                                    {
                                      window.months_th.map((month, month_i) => (
                                        <option value={month_i} key={month_i}>{month}</option>
                                      ))
                                    }
                                  </select>
                                  : null
                              }
                              {
                                this.state.timerage === 'รายปี'
                                  || this.state.timerage === 'รายเดือน' ?
                                  <select className={"form-control form-control-sm"}

                                    style={{ flex: 1 }}
                                    name={"year"}
                                    onChange={(e) => {
                                      this.setState({
                                        year: e.target.value,
                                        is_chart_loading: true,
                                      }, () => {
                                        this.fetchBookng();
                                      })
                                    }}
                                    defaultValue={this.state.year}
                                  >
                                    {
                                      this.state.years.map((years) => (
                                        <option value={years} key={years}>{years}</option>
                                      ))
                                    }
                                  </select>
                                  : null
                              }
                            </div>
                          </div>
                        </div>
                        <div className="divider" />
                        {/* <p className="text-center">
                                                    <strong>Goal Completion</strong>
                                                </p> */}

                        <div className="progress-group">
                          แม่บ้านทั้งหมด
                          <span className="float-right">{this.state.all_provider} คน</span>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-primary" style={{ width: '100%' }}></div>
                          </div>
                        </div>
                        <div className="progress-group">
                          Booking ทั้งหมด
                          <span className="float-right">{this.state.all_booking} ครั้ง</span>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-warning" style={{ width: '100%' }}></div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="card-footer" style={{ display: 'block' }}>
                    <div className="flex row flex-fluid-item">
                      <div >
                        <div className="description-block border-right">
                          {/* <span className="description-percentage text-success"><i className="fas fa-caret-up"></i> 17%</span> */}
                          <h5 className="description-header" style={{ fontSize: 20 }}>{this.state.time} ชม.</h5>
                          <span className="description-text">จำนวนชั่วโมงเรียกใช้งาน</span>
                        </div>
                      </div>
                      <div >
                        <div className="description-block border-right">
                          {/* <span className="description-percentage text-success"><i className="fas fa-caret-up"></i> 20%</span> */}
                          <h5 className="description-header" style={{ fontSize: 20 }}>฿{Helper.numberFormat(this.state.commissionAll.toFixed(2))}</h5>
                          <span className="description-text">คอมมิชชั่น</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*PROVIDER*/}
              <div className={"col-lg-6"}>
                <div className="info-box mb-3">
                  <span className="info-box-icon bg-primary elevation-1"><i className="fas fa-user-plus"></i></span>
                  <div className="info-box-content">
                    <span className="info-box-text">สมัครแม่บ้านใหม่</span>
                    <span className="info-box-number">{Helper.numberFormat(this.state.provider_all_item)} คน</span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <Link to={"/provider/report"} style={{ marginLeft: 10 }}>
                      <button className="btn btn-default">รายงานแม่บ้าน</button>
                    </Link>
                  </div>
                </div>
                <div className="card collapsed-card">
                  <div className="card-header">
                    <h3 className="card-title">สมัครแม่บ้านวันนี้</h3>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse">
                        <i className="fas fa-plus"></i>
                      </button>
                      {/* <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                    <i className="fas fa-times"></i>
                                                </button> */}
                    </div>
                  </div>
                  <div className="card-body p-0" style={{ display: 'none' }}>
                    {
                      this.state.is_loading_provider ?
                        <div style={{ padding: '1rem 0', textAlign: 'center' }}>
                          <div className="ui active inline loader small" ></div>
                        </div>
                        :
                        this.state.providers.length === 0 ?
                          <div style={{ padding: '1rem 0', textAlign: 'center' }}>
                            ไม่มีสมาชิกใหม่
                          </div>
                          :
                          <ul className="products-list product-list-in-card pl-2 pr-2">
                            {
                              this.state.providers.map((user, user_i) => {
                                return (
                                  <li className="item" key={user_i}>
                                    <div  >
                                      <Link style={{ color: '#333' }} className="product-title" to={"/provider-users/" + user.id}>{user.prefix} {user.firstName} {user.lastName}
                                        <span className={"badge badge-primary"} style={{ marginLeft: 5 }}>
                                          {user.gender === "ชาย" ? <><i className="fas fa-mars"></i> {user.gender}</> : null}
                                          {user.gender === "หญิง" ? <><i className="fas fa-venus"></i> {user.gender}</> : null}
                                        </span>
                                        <div className="float-right">
                                          {
                                            user.status === 'waitForApprove' ?
                                              <><i className={"fas fa-circle"} style={{ color: '#ffa200' }} /> รอการยืนยัน</>
                                              : null
                                          }
                                          {
                                            user.status === 'approved' ?
                                              <><i className={"fas fa-circle"} style={{ color: 'blue' }} /> ยืนยันแล้ว</>
                                              : null
                                          }
                                          {
                                            user.status === 'avaliable' ?
                                              <><i className={"fas fa-circle"} style={{ color: 'green' }} /> ใช้งานได้</>
                                              : null
                                          }
                                          {
                                            user.status === 'ban' ?
                                              <><i className={"fas fa-circle"} style={{ color: 'red' }} /> ระงับการใช้งาน</>
                                              : null
                                          }
                                        </div>
                                      </Link>
                                      <span className="product-description">
                                        <div><i className="fas fa-phone"></i>  {user.phoneNumber}</div>
                                        <div>
                                          <i className="fab fa-line"></i> {user.lineID}
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                );
                              })
                            }

                          </ul>
                    }

                  </div>

                  {
                    !this.state.is_loading_provider
                      && this.state.providers.length !== 0 ?
                      <div className="card-footer">
                        <Pagination
                          now={this.state.provider_page}
                          all_items={this.state.provider_all_item}
                          limit={this.state.provider_limit}
                          location={this.props.location}
                          onReturnPage={(page) => {
                            this.setState({
                              provider_page: page,
                              is_loading_provider: true,
                            }, () => {
                              this.fetchProviderData();
                            })
                          }}
                        />
                      </div>
                      : null
                  }
                </div>

              </div>

            </div>

          </div>
        </section>
      </>
    );
  }
}