import React from 'react';

export default class ComfirmModal extends React.Component {
    componentDidMount() {
        this.$modal.modal('show');
    }

    render() {
        return (
            <div ref={(ref) => {
                this.$modal = window.$(ref);
                this.$modal.modal('show');
                this.$modal.on('hidden.bs.modal', () => {
                    if (this.props.onCancel) {
                        this.props.onCancel();
                    }
                    let settime = window.setTimeout(() => {
                        this.$modal.remove();
                        clearTimeout(settime);
                    }, 500)
                });
            }}
                className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{this.props.title}</h4>
                        </div>
                        <div className="modal-body">
                            <p style={{marginBottom: 0}}>{this.props.description}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={() => {
                                    if (this.props.onCancel) {
                                        this.props.onCancel();
                                    }
                                    this.$modal.modal('hide');
                                }}
                            >{this.props.cancle_text || "ยกเลิก"}</button>
                            <button type="button" className="btn btn-primary"
                                ref={ref => { this.$btn = window.$(ref) }}
                                onClick={() => {
                                    if (this.$btn.hasClass('loading')) { return; }
                                    this.$btn.addClass("loading")
                                    if (this.props.onConfirm) {
                                        this.props.onConfirm();
                                        this.$modal.modal('hide');
                                    }
                                }}
                            >{this.props.submit_text || "ตกลง"}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}