import React, { Component } from 'react'
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';
import { Helper } from '../Helper';
import { storage } from '../firebase';
const axios = require('axios').default;
const default_required = true;
export default class AgentUpdateInformation extends Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            bank_list: [
                "ธนาคารกรุงเทพ",
                "ธนาคารกสิกรไทย",
                "ธนาคารกรุงไทย",
                "ธนาคารทหารไทย",
                "ธนาคารไทยพาณิชย์",
                "ธนาคารกรุงศรีอยุธยา",
                "ธนาคารเกียรตินาคินภัทร",
                "ธนาคารซีไอเอ็มบีไทย",
                "ธนาคารทิสโก้",
                "ธนาคารธนชาต",
                "ธนาคารยูโอบี",
                "ธนาคารไทยเครดิตเพื่อรายย่อย",
                "ธนาคารแลนด์แอนด์ เฮ้าส์",
                "ธนาคารไอซีบีซี (ไทย)",
                "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
                "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
                "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
                "ธนาคารออมสิน",
                "ธนาคารอาคารสงเคราะห์",
                "ธนาคารอิสลามแห่งประเทศไทย"
            ],
            uploaded_images: [],
        }
    }
    prepareData(data) {
        let uploadimages = [];
        if (this.$profileImage
            && this.$profileImage[0]
            && this.$profileImage[0].files
            && this.$profileImage[0].files[0]) {
            uploadimages.push({ file: this.$profileImage[0].files[0], valueName: 'profileImage' });
        }
        if (this.$personalCardImage
            && this.$personalCardImage[0]
            && this.$personalCardImage[0].files
            && this.$personalCardImage[0].files[0]) {
            uploadimages.push({ file: this.$personalCardImage[0].files[0], valueName: 'personalCardImage' });
        }
        if (this.$bookBankImage
            && this.$bookBankImage[0]
            && this.$bookBankImage[0].files
            && this.$bookBankImage[0].files[0]) {
            uploadimages.push({ file: this.$bookBankImage[0].files[0], valueName: 'bookBankImage' });
        }
        if (this.$addressBookImage
            && this.$addressBookImage[0]
            && this.$addressBookImage[0].files
            && this.$addressBookImage[0].files[0]) {
            uploadimages.push({ file: this.$addressBookImage[0].files[0], valueName: 'addressBookImage' });
        }
        if (uploadimages.length != 4) {
            Swal.fire({
                title: 'กรุณาอัพโหลดรูปภาพ',
                icon: 'warning'
            })
            return;
        }

        Swal.fire({
            title: 'กำลังอัพโหลดรูปภาพ...',
            html: '<b></b>',
            timerProgressBar: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
                Swal.showLoading();
            }
        }) // loading for block all active
        this.uploadFiles(uploadimages, 0, data); // upload first image
    }

    async uploadFiles(file_stack, upload_index, data) {
        let fileName = file_stack[upload_index].valueName;
        let file = file_stack[upload_index].file;
        let imageDir = "agentInformation/"
        const filename = Helper.setNewImageFileName(file);
        let reszieFile = file;
        // let reszieFile = await Helper.resizeImageFn(file, {
        //     maxWidth: 2500,
        //     maxHeight: 2500,
        // });
        var uploadTask = storage.ref().child(imageDir + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {
        }, async (error) => {
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            for (const uploaded_image of this.state.uploaded_images) {
                await storage.ref().child(uploaded_image.storagePath).delete();
            }
            this.state.uploaded_images = [];
            this.submit.removeClass('loading');
            return;
        }, async () => {
            let url = imageDir + filename;
            const photoURL = await storage.ref(url).getDownloadURL();
            let uploaded_images = [...this.state.uploaded_images];
            uploaded_images.push({
                imagePath: photoURL,
                storagePath: url,
                inputName: fileName,
            })
            this.state.uploaded_images = uploaded_images; // upload success push data in state
            upload_index++;
            if (upload_index >= file_stack.length) {
                this.onUpdate(data); // no upload image
                return;
            }
            if (upload_index < file_stack.length) {
                this.uploadFiles(file_stack, upload_index, data,) // upload next image
            }
        });
    }
    async onUpdate(data) {
        data.images = this.state.uploaded_images;
        console.log(data);
        let res = await axios.put(`${window.api_host_agent}/agents/${this.props.agent.uid}`, data);
        if (res.data.success) {
            Helper.messageTop({ message: 'ทำการเพิ่มข้อมูลสำเร็จ, กรุณารอสักครู่', type_class: 'alert-success' });
            Helper.setCookie('user', JSON.stringify(res.data.agent), 1)
            window.location = '/';
            return;
        } else {
            Swal.fire({
                title: 'เกิดข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
                icon: 'error'
            })
        }
    }
    render() {
        return (
            <div className={"hold-transition login-page login_background"}>
                <div>

                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
                <form className='container'
                    ref={(ref) => this.$form = window.$(ref)}
                    onSubmit={(e) => {
                        e.preventDefault();
                        let data = {};
                        this.$form.serializeArray().map((item) => {
                            data[item.name] = item.value;
                            return true
                        })
                        data.isAgentUpdatedInformation = true
                        console.log(data);
                        let filled = true;
                        if (!data.personalNo
                            || !data.prefix
                            || !data.gender
                            || !data.bank
                        ) {
                            filled = false;
                        }
                        if (!filled) {
                            Swal.fire({
                                title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
                                icon: 'warning'
                            })
                            return;
                        }
                        this.prepareData(data);
                    }}
                >

                    <div className="card">
                        <div className="login-logo">
                            <img src="assets/images/logo.png" alt="Beeclean Logo" style={{ maxHeight: 150 }} />
                        </div>
                        <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0, marginTop: -20 }}>AGENT</h3>
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">กรอกข้อมูลเพื่อเข้าใช้ระบบ</p>
                            <div className='row'>
                                <div className='col-12 col-md-4 '>
                                    <div className="mb-3">
                                        <label>รูปโปรไฟล์</label>
                                        <input name="image"
                                            type={"file"}
                                            ref={(ref) => this.$profileImage = window.$(ref)}
                                            style={{ display: 'none' }}
                                            id="file_image"
                                            onChange={(e) => {
                                                Helper.readURL(this.$profileImage);
                                            }}
                                        />
                                        <div id="show_image" />
                                        <label htmlFor="file_image"
                                            type={"button"}
                                            className="btn btn-default"
                                            style={{ width: '100%' }}
                                        >
                                            อัพโหลด
                                        </label>
                                    </div>
                                    <div className='mb-3'>
                                        <InputMask
                                            mask="9  9 9 9 9 9  9 9 9 9  9 9  9"
                                            placeholder={"เลขบัตรประชาชน"}
                                            className="form-control"
                                            onChange={(e) => {
                                                let personalNo = e.target.value.trim();
                                                let result = personalNo.replace(/\s/g, "");
                                                this.$personalNo.val(result)
                                            }}
                                        />
                                        <input name={"personalNo"} type={"hidden"} ref={(ref) => { this.$personalNo = window.$(ref) }} />
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-sm-6"}>
                                            <div className="mb-3">
                                                <select
                                                    name={"prefix"}
                                                    className="form-control placeholder_font"
                                                    ref={(ref) => { this.$prefix = window.$(ref) }}
                                                    onChange={(e) => {
                                                        if (e.target.value === "") {
                                                            this.$prefix.addClass("placeholder_font");
                                                        }
                                                        if (e.target.value !== "") {
                                                            this.$prefix.removeClass("placeholder_font");
                                                        }
                                                    }}
                                                >
                                                    <option value="">คำนำหน้านาม</option>
                                                    <option value="นาย">นาย</option>
                                                    <option value="นาง">นาง</option>
                                                    <option value="นางสาว">นางสาว</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-sm-6"}>
                                            <div className="mb-3">
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder={"ชื่อ"}
                                                    name={"firstName"}
                                                    required={default_required}
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-sm-6"}>
                                            <div className="mb-3">
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder={"นามสกุล"}
                                                    name="lastName"
                                                    required={default_required}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <input type="number" className="form-control" placeholder={"เบอร์โทรศัพท์"} name={"phoneNumber"}
                                            required={default_required}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <select
                                            className="form-control placeholder_font"
                                            name={"gender"}
                                            ref={(ref) => { this.$gender = window.$(ref) }}
                                            defaultValue={""}
                                            onChange={(e) => {
                                                if (e.target.value === "") {
                                                    this.$gender.addClass("placeholder_font");
                                                }
                                                if (e.target.value !== "") {
                                                    this.$gender.removeClass("placeholder_font");
                                                }
                                            }}
                                        >
                                            <option value="">เพศ</option>
                                            <option value="ชาย">ชาย</option>
                                            <option value="หญิง">หญิง</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-12 col-md-4 '>
                                    <label>ข้อมูลที่อยู่</label>
                                    <div className="mb-3">
                                        <textarea
                                            defaultValue={''}
                                            className={"form-control"}
                                            placeholder={"ที่อยู่ตามบัตรประชาชน"}
                                            name="address"
                                            required={default_required}
                                            onChange={(e) => {
                                                let deliveryAddressRef = window.$('textarea[name="deliveryAddress"]');
                                                if (this.$checkboxHaveLink.prop("checked")) {
                                                    deliveryAddressRef.val(e.target.value).attr('readonly', true);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={"เลขไปรษณีย์"}
                                            name={"zipcode"}
                                            required={default_required}
                                            onChange={(e) => {
                                                let deliveryZipcodeRef = window.$('input[name="deliveryZipcode"]');
                                                if (this.$checkboxHaveLink.prop("checked")) {
                                                    deliveryZipcodeRef.val(e.target.value).attr('readonly', true);
                                                }
                                            }}
                                        />
                                    </div>
                                    <hr />

                                    <div className={"mb-3"}>
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox"
                                                ref={(ref) => { this.$checkboxHaveLink = window.$(ref) }}
                                                className="custom-control-input"
                                                id="addressSame"
                                                name={"addressSame"}
                                                defaultValue={"on"}
                                                defaultChecked={this.props.promotion_slide && this.props.promotion_slide.haveLink}
                                                onChange={() => {
                                                    let addressRef = window.$('textarea[name="address"]');
                                                    let zipcodeRef = window.$('input[name="zipcode"]');
                                                    let deliveryAddressRef = window.$('textarea[name="deliveryAddress"]');
                                                    let deliveryZipcodeRef = window.$('input[name="deliveryZipcode"]');
                                                    if (this.$checkboxHaveLink.prop("checked")) {
                                                        deliveryAddressRef.val(addressRef.val()).attr('readonly', true);
                                                        deliveryZipcodeRef.val(zipcodeRef.val()).attr('readonly', true);
                                                        // window.$('#deliveryAddress').show()
                                                    } else {
                                                        deliveryAddressRef.val("").attr('readonly', false);
                                                        deliveryZipcodeRef.val("").attr('readonly', false);
                                                        // window.$('#deliveryAddress').hide()
                                                    }
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="addressSame">เหมือนที่อยู่ตามบัตรประชาชน</label>
                                        </div>
                                    </div>
                                    <div id="deliveryAddress">
                                        <div className="mb-3">
                                            <textarea defaultValue={''} className={"form-control"} placeholder={"ที่อยู่จัดส่งเอกสาร"} name="deliveryAddress" required={default_required} />
                                        </div>
                                        <div className="mb-3">
                                            <input type="number" className="form-control" placeholder={"เลขไปรษณีย์"} name={"deliveryZipcode"} required={default_required} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-4'>
                                    <label>ข้อมูลธนาคาร</label>
                                    <div className="mb-3">
                                        <select className="form-control" name={"bank"} defaultValue={''} required={default_required}>
                                            <option value={""}>เลือกธนาคาร</option>
                                            {
                                                this.state.bank_list.map((bank, bank_i) => {
                                                    return (
                                                        <option key={bank_i} value={bank}>{bank}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <input name="bankBranch" defaultValue={""} className="form-control" required={default_required} placeholder="สาขา" />
                                    </div>
                                    <div className="mb-3">
                                        <input name="bankAccountName" defaultValue={""} className="form-control" required={default_required} placeholder="ชื่อบัญชี" />
                                    </div>
                                    <div className="mb-3">
                                        <input name="bankAccountNo" defaultValue={""} className="form-control" required={default_required} placeholder="เลขบัญชี" />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className='row'>
                                <div className='col-12 col-sm-3'>
                                    <div className="mb-3">
                                        <label>สำเนาบัตรประชาชน</label>
                                        <input name="image"
                                            type={"file"}
                                            ref={(ref) => this.$personalCardImage = window.$(ref)}
                                            style={{ display: 'none' }}
                                            id="personalCardImage"
                                            onChange={(e) => {
                                                Helper.readURL(this.$personalCardImage);
                                            }}
                                        />
                                        <div id="show_image" />
                                        <label htmlFor="personalCardImage"
                                            type={"button"}
                                            className="btn btn-default"
                                            style={{ width: '100%' }}
                                        >
                                            อัพโหลด
                                        </label>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-3'>
                                    <div className="mb-3">
                                        <label>สำเนาสมุดบัญชีธนาคาร</label>
                                        <input name="image"
                                            type={"file"}
                                            ref={(ref) => this.$bookBankImage = window.$(ref)}
                                            style={{ display: 'none' }}
                                            id="bookBankImage"
                                            onChange={(e) => {
                                                Helper.readURL(this.$bookBankImage);
                                            }}
                                        />
                                        <div id="show_image" />
                                        <label htmlFor="bookBankImage"
                                            type={"button"}
                                            className="btn btn-default"
                                            style={{ width: '100%' }}
                                        >
                                            อัพโหลด
                                        </label>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-3'>
                                    <div className="mb-3">
                                        <label>สำเนาทะเบียนบ้าน</label>
                                        <input name="image"
                                            type={"file"}
                                            ref={(ref) => this.$addressBookImage = window.$(ref)}
                                            style={{ display: 'none' }}
                                            id="addressBookImage"
                                            onChange={(e) => {
                                                Helper.readURL(this.$addressBookImage);
                                            }}
                                        />
                                        <div id="show_image" />
                                        <label htmlFor="addressBookImage"
                                            type={"button"}
                                            className="btn btn-default"
                                            style={{ width: '100%' }}
                                        >
                                            อัพโหลด
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='d-flex w-100'>
                                <button
                                    style={{ marginRight: 15 }}
                                    type="button"
                                    className='btn btn-default btn-lg'
                                    onClick={() => {

                                        Helper.setCookie('user', "", 1);
                                        window.location = '/';
                                    }}
                                >ออกจากระบบ</button>
                                <button type="submit"
                                    ref={(ref) => { this.$submitBtn = window.$(ref); }}
                                    className="btn btn-primary btn-lg"
                                    style={{ marginLeft: 'auto' }}
                                >บันทึก</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
