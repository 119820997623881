import React from 'react';
import { Link, navigate } from '@reach/router';
import { db } from './../../firebase';
import { Helper } from './../../Helper';
import CustomDateInput from './../../Components/CustomDateInput';

import DatePicker from "react-datepicker";
import { storage } from './../../firebase';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProviderPaymentCard from './../../Components/ProviderPaymentCard';
const thailandJson = require('./../../thailand.json');
const axios = require('axios').default;
export default class ViewUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            booking: false,
            booking_id: props.booking_id,
            is_payment_loading: true,
            is_loading_time_plus: true,
        }
    }

    async componentDidMount() {
        this.fetch();
    }
    async fetch() {
        let qs = {};
        qs.bookingID = this.state.booking_id;
        let res = await axios.get(`${window.api_host}/booking-detail?${window.$.param(qs)}`);
        // console.log(res)
        let _booking = false;
        let payment = false;
        if (res.data.success) {
            _booking = res.data.booking;
            payment = res.data.payment;
        }
        this.setState({
            booking: _booking,
            payment: payment,
            is_loading: false,
            is_loading_time_plus: false,
        })
    }
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };
        let summary = Helper.bookingSummary({ booking: this.state.booking, payment: this.state.payment });
        // console.log(summary)
        let plustime_price = summary.plustime_price;
        let plustime_total_price = summary.plustime_total_price;
        let plustime_app_price = summary.plustime_app_price;
        let plustime_provider_price = summary.plustime_provider_price;
        let hours = summary.hours;
        let total_price = summary.total_price;
        let wait_count = 0;
        let total_discount = summary.total_discount;


        let totalAdjust = 0;
        if (this.state.booking && this.state.booking.adjustSalary) {
            for (const adj of this.state.booking.adjustSalary) {
                if (adj.type == 'special_wallet') {
                    totalAdjust += parseFloat(adj.amount)
                }
                if (adj.type == 'withhold_wallet') {
                    totalAdjust -= parseFloat(adj.amount)
                }
            }
        }
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ข้อมูล Booking </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/books">Booking</Link></li>
                                    <li className="breadcrumb-item active">ข้อมูล Booking</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.booking ?
                                <>
                                    <div className={"row"}>
                                        <div className={"col-sm-12 col-lg-4"}>
                                            <div className="card">
                                                <div className="card-header" style={{ alignItems: 'center', display: 'flex' }}>
                                                    <h4 className="card-title" id="myModalLabel">Booking No : {this.state.booking.bookOn} {this.state.booking.createdAdmin ? <div className="badge badge-warning">สร้างจากระบบหลังบ้าน</div> : null}</h4>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        this.state.booking.createdAdmin ?
                                                            <>
                                                                <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                                    <div><label>สร้างโดย</label> : {this.state.booking.createdAdmin.displayName}</div>
                                                                </div>
                                                                <div className="divider" />
                                                            </>
                                                            : null
                                                    }
                                                    <div className={"flex row"} style={{ alignItems: 'center' }}>
                                                        <div><label>สถานะ</label> : {' '}
                                                            {
                                                                this.state.booking.bookStatus === 'accept' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "blue" }}></i>  ยอมรับ</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'arrive' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "purple" }}></i>  ถึงแล้ว</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'done' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "lightgreen" }}></i>  เสร็จสิ้น</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'finish' && parseFloat(this.state.booking.starFromProvider) === 0 && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "green" }}></i>  เสร็จงาน</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'finish' && parseFloat(this.state.booking.starFromProvider) > 0 && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <>
                                                                        <i className={"fas fa-circle"} style={{ color: "darkgreen" }}></i>  ส่งงาน
                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'going' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "orange" }}></i>  กำลังเดินทาง</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'wait' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "yellow" }}></i>  รอ</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'working' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "darkblue" }}></i>  กำลังทำงาน</>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.bookStatus === 'cancel' && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "red" }}></i>  ยกเลิก
                                                                        {this.state.booking.cancelRemark ? <div style={{ marginBottom: '0.5rem' }}>{this.state.booking.cancelRemark}</div> : null}
                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.booking.paymentStatus === 'refund' ?
                                                                    <><i className={"fas fa-circle"} style={{ color: "red" }}></i>  คืนเงิน</>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.booking.updateAdmin ?
                                                            <div className={"flex row"}>
                                                                <div>
                                                                    <label>แก้ไขล่าสุด</label> : {' '} {this.state.booking.updateAdmin.displayName}
                                                                </div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {Helper.getDateThai(this.state.booking.updateAdmin.updatedAt, { is_show_time: true })}
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.booking.cancelAdmin ?
                                                            <div className={"flex row"}>
                                                                <div>
                                                                    <label>ผู้ยกเลิก</label> : {' '} {this.state.booking.cancelAdmin.displayName}
                                                                </div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {Helper.getDateThai(this.state.booking.cancelAt, { is_show_time: true })}
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                    <div className={"divider"} />
                                                    <div className={"flex row"}>
                                                        <div><label>สมาชิก</label> : <span to={'/user/' + this.state.booking.userUid}>{this.state.booking.user.displayName}</span> </div>
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            {this.state.booking.user && this.state.booking.user.phoneNumber ?
                                                                <><i className={"fas fa-phone"} /> {this.state.booking.user.phoneNumber.replace('+66', '0')}</>
                                                                : null
                                                            }
                                                            {this.state.booking.user && this.state.booking.user.guestPhoneNumber && !this.state.booking.user.phoneNumber ?
                                                                <><i className={"fas fa-phone"} /> {this.state.booking.user.guestPhoneNumber.replace('+66', '0')}</>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={"flex row"}>
                                                        <div><label>แม่บ้าน</label> :  {this.state.booking.providerUser.provideruserDataUID ?
                                                            <Link to={'/maid/' + this.state.booking.providerUser.provideruserDataUID}>
                                                                {this.state.booking.providerUser.displayName + ' '}
                                                            </Link>
                                                            : this.state.booking.providerUser.displayName ? this.state.booking.providerUser.displayName + ' ' : null}
                                                            {
                                                                !this.state.booking.providerUser.provideruserDataUID && this.state.booking.paymentStatus === 'success' ?
                                                                    <span className="badge badge-warning salary-custom-badge" style={{ marginRight: 5 }}>กำลังค้นหางาน</span>
                                                                    : null
                                                            }
                                                            {
                                                                !this.state.booking.providerUser.provideruserDataUID && this.state.booking.paymentStatus === 'wait' ?
                                                                    <span className="badge badge-info salary-custom-badge" style={{ marginRight: 5 }}>รอชำระเงิน</span>
                                                                    : null
                                                            }
                                                            {
                                                                !this.state.booking.providerUser.provideruserDataUID && this.state.booking.paymentStatus === 'fail' ?
                                                                    <span className="badge badge-danger salary-custom-badge" style={{ marginRight: 5 }}>ชำระเงินไม่สำเร็จ</span>
                                                                    : null
                                                            }
                                                        </div>
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            {this.state.booking.providerUser && this.state.booking.providerUser.phoneNumber ?
                                                                <>
                                                                    <i className={"fas fa-phone"} /> {this.state.booking.providerUser.phoneNumber.replace('+66', '0')}
                                                                </>
                                                                : null
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className={"divider"} />
                                                    <div>
                                                        <label>ระยะเวลาที่ให้บริการ</label> :  {' '}
                                                        {this.state.booking.address && this.state.booking.bookTime && this.state.booking.bookTime.name_th ?
                                                            this.state.booking.bookTime.name_th
                                                            : null
                                                        }
                                                    </div>
                                                    <div>
                                                        <label>วันเวลาที่จอง</label> :  {' '}
                                                        {
                                                            this.state.booking.createdAt ?
                                                                Helper.getDateThai(this.state.booking.createdAt, { is_show_time: true }) : null
                                                        }
                                                    </div>
                                                    <div>
                                                        <label>วันเวลาที่ให้บริการ</label> :  {' '}
                                                        {
                                                            this.state.booking.bookedAt ?
                                                                Helper.getDateThai(this.state.booking.bookedAt, { is_show_time: true }) : null
                                                        }
                                                        {
                                                            ((this.state.booking.bookStatus === 'accept'
                                                                || this.state.booking.bookStatus === 'going'
                                                                || this.state.booking.bookStatus === 'arrive')
                                                                && this.state.booking.paymentStatus === 'success')
                                                                || (this.state.booking.bookHistoryChangeTime
                                                                    && this.state.booking.bookHistoryChangeTime.length > 0) ?
                                                                <div style={{ fontSize: 18, marginBottom: "0.5rem" }}>

                                                                    {
                                                                        this.state.booking.bookHistoryChangeTime
                                                                            && this.state.booking.bookHistoryChangeTime.length > 0 ?
                                                                            <button className={"btn btn-sm btn-default"}
                                                                                onClick={() => {
                                                                                    Helper.reactToDom(
                                                                                        window.$('body'),
                                                                                        <BookingDateTimeList
                                                                                            data={this.state.booking.bookHistoryChangeTime}
                                                                                        />
                                                                                    );
                                                                                }}
                                                                            >
                                                                                ประวัติการเปลี่ยนวันเวลาที่ให้บริการ
                                                                            </button>
                                                                            : null
                                                                    }
                                                                </div>
                                                                : null
                                                        }

                                                    </div>
                                                    <div className={"divider"} />
                                                    <div>
                                                        {this.state.booking.address
                                                            && this.state.booking.address.name ?
                                                            <div><label>ชื่อสถานที่ : </label> {this.state.booking.address.name}</div>
                                                            : null
                                                        }
                                                        {this.state.booking.address
                                                            && this.state.booking.address.placeType
                                                            && this.state.booking.address.placeType.name_th ?
                                                            <div><label>ประเภท : </label> {this.state.booking.address.placeType.name_th}</div>
                                                            : null
                                                        }
                                                        {this.state.booking.address
                                                            && this.state.booking.address.placeType
                                                            && this.state.booking.address.placeType.place_sub_type
                                                            && this.state.booking.address.placeType.place_sub_type.description_th ?
                                                            <div><label>พืนที่ : </label> {this.state.booking.address.placeType.place_sub_type.description_th}</div>
                                                            : null
                                                        }
                                                        {this.state.booking.address
                                                            && this.state.booking.address.phoneNumber ?
                                                            <div><label>เบอร์โทรศัพท์ : </label> {this.state.booking.address.phoneNumber.replace('+66', '0')}</div>
                                                            : null
                                                        }

                                                        {this.state.booking.address
                                                            && this.state.booking.address.province
                                                            && this.state.booking.address.amphoe ?
                                                            <div>{this.state.booking.address.addressDetail}
                                                                {this.state.booking.address.province == '10' ? " เขต" : " อำเภอ"}
                                                                {thailandJson[this.state.booking.address.province].amphoes[this.state.booking.address.amphoe].name.th}
                                                                {" จังหวัด"}{thailandJson[this.state.booking.address.province].name.th} {" "} {this.state.booking.address.note}
                                                                {/* {this.state.booking.address.address} */}
                                                            </div>
                                                            : this.state.booking.address && this.state.booking.address.address ? this.state.booking.address.address
                                                                : null
                                                        }
                                                        {
                                                            this.state.booking.address
                                                                && this.state.booking.address.coordinate
                                                                && this.state.booking.address.coordinate._latitude
                                                                && this.state.booking.address.coordinate._longitude ?
                                                                <>
                                                                    <a href={"https://maps.google.com?q=" + this.state.booking.address.coordinate._latitude + ',' + this.state.booking.address.coordinate._longitude}
                                                                        className={"btn btn-default"}
                                                                        target="_blank"
                                                                        rel='noopener noreferrer'
                                                                    >
                                                                        ดูสถานที่
                                                                    </a>
                                                                </>
                                                                : null
                                                        }
                                                        <div className={"divider mt-2"} />
                                                        <div className='mt-1'>
                                                            <label>หมายเหตุ : </label> {this.state.booking.note ? this.state.booking.note : '-'} {' '}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.booking.acceptAt
                                                    || this.state.booking.goingAt
                                                    || this.state.booking.arriveAt
                                                    || this.state.booking.workingAt
                                                    || ((this.state.booking.bookStatus === 'done'
                                                        || this.state.booking.bookStatus === 'finish')
                                                        && this.state.booking.doneAt)
                                                    || this.state.booking.finishAt ?
                                                    <div className="card">
                                                        <div className="card-header" style={{ alignItems: 'center' }}>
                                                            <h4 className="card-title" id="myModalLabel">สถานะและเวลาการทำงาน</h4>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-minus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className={"timeline"}>
                                                                {
                                                                    this.state.booking.acceptAt ?
                                                                        <div>
                                                                            <i className="far fa-handshake bg-blue"></i>
                                                                            <div className="timeline-item">
                                                                                <span className="time">
                                                                                    <div><i className="far fa-clock"></i> {Helper.getDateThai(this.state.booking.acceptAt, { is_show_time: true })}</div>
                                                                                    {
                                                                                        this.state.booking.acceptAdmin ?
                                                                                            <div><i className="fas fa-user-cog"></i> {this.state.booking.acceptAdmin.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                                <h3 className="timeline-header no-border">ยอมรับ</h3>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    this.state.booking.goingAt ?
                                                                        <div>
                                                                            <i className="fas fa-road bg-yellow"></i>
                                                                            <div className="timeline-item">
                                                                                <span className="time">
                                                                                    <div><i className="far fa-clock"></i> {!this.state.booking.goingAdmin ? Helper.getDateThai(this.state.booking.goingAt - (7 * 3600 * 1000), { is_show_time: true }) : Helper.getDateThai(this.state.booking.goingAt, { is_show_time: true })}</div>
                                                                                    {
                                                                                        this.state.booking.goingAdmin ?
                                                                                            <div><i className="fas fa-user-cog"></i> {this.state.booking.goingAdmin.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                                <h3 className="timeline-header no-border">เริ่มเดินทาง</h3>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    this.state.booking.arriveAt ?
                                                                        <div>
                                                                            <i className="fas fa-map-marked-alt" style={{ background: 'darkblue', color: '#fff' }}></i>
                                                                            <div className="timeline-item">
                                                                                <span className="time">
                                                                                    <div><i className="far fa-clock"></i> {!this.state.booking.arriveAdmin ? Helper.getDateThai(this.state.booking.arriveAt - (7 * 3600 * 1000), { is_show_time: true }) : Helper.getDateThai(this.state.booking.arriveAt, { is_show_time: true })}</div>
                                                                                    {
                                                                                        this.state.booking.arriveAdmin ?
                                                                                            <div><i className="fas fa-user-cog"></i> {this.state.booking.arriveAdmin.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                                <h3 className="timeline-header no-border">ถึง</h3>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    this.state.booking.workingAt ?
                                                                        <div>
                                                                            <i className="fas fa-broom" style={{ background: 'purple', color: '#fff' }}></i>
                                                                            <div className="timeline-item">
                                                                                <span className="time">
                                                                                    <div><i className="far fa-clock"></i> {!this.state.booking.workingAdmin ? Helper.getDateThai(this.state.booking.workingAt - (7 * 3600 * 1000), { is_show_time: true }) : Helper.getDateThai(this.state.booking.workingAt, { is_show_time: true })}</div>
                                                                                    {
                                                                                        this.state.booking.workingAdmin ?
                                                                                            <div><i className="fas fa-user-cog"></i> {this.state.booking.workingAdmin.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                                <h3 className="timeline-header no-border">เริ่มทำงาน</h3>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (this.state.booking.bookStatus === 'done'
                                                                        || this.state.booking.bookStatus === 'finish')
                                                                        && this.state.booking.doneAt ?
                                                                        <div>
                                                                            <i className="fas fa-hand-sparkles" style={{ background: 'lightgreen', color: '#000' }}></i>
                                                                            <div className="timeline-item">
                                                                                <span className="time">
                                                                                    <div><i className="far fa-clock"></i> {!this.state.booking.doneAdmin ? Helper.getDateThai(this.state.booking.doneAt - (7 * 3600 * 1000), { is_show_time: true }) : Helper.getDateThai(this.state.booking.doneAt, { is_show_time: true })}</div>
                                                                                    {
                                                                                        this.state.booking.doneAdmin ?
                                                                                            <div><i className="fas fa-user-cog"></i> {this.state.booking.doneAdmin.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                                <h3 className="timeline-header no-border">เสร็จงาน</h3>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    this.state.booking.finishAt ?
                                                                        <div>
                                                                            <i className="fas fa-check bg-green"></i>
                                                                            <div className="timeline-item">
                                                                                <span className="time">
                                                                                    <div><i className="far fa-clock"></i> {!this.state.booking.finishAdmin ? Helper.getDateThai(this.state.booking.finishAt - (7 * 3600 * 1000), { is_show_time: true }) : Helper.getDateThai(this.state.booking.finishAt, { is_show_time: true })}</div>
                                                                                    {
                                                                                        this.state.booking.finishAdmin ?
                                                                                            <div><i className="fas fa-user-cog"></i> {this.state.booking.finishAdmin.displayName}</div>
                                                                                            : null
                                                                                    }
                                                                                </span>
                                                                                <h3 className="timeline-header no-border">ส่งงาน</h3>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div>
                                                                    <i className="fas fa-clock bg-gray"></i>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                        </div>
                                        <div className={"col-sm-12 col-lg-4"}>
                                            {
                                                this.state.payment
                                                    && (this.state.booking.paymentStatus === "successful"
                                                        || this.state.booking.paymentStatus === "success"
                                                        || this.state.booking.paymentStatus === "finish") ?
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title" id="myModalLabel">สรุปผลค่าบริการ</h4>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-minus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div>
                                                                {/* <div className={"flex row"}>
                                                                    <div><label>ค่าบริการเรียกเก็บจาก สมาชิก</label></div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {parseFloat(plustime_total_price) > 0 ? Helper.numberFormat(plustime_total_price, { decimal: 2 }) : 0} บาท
                                                                    </div>
                                                                </div> */}
                                                                <div className={"flex row"}>
                                                                    <div><label>ค่าบริการที่แม่บ้านได้รับ</label></div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {parseFloat(plustime_provider_price) > 0 ? Helper.numberFormat(plustime_provider_price, { decimal: 2 }) : 0} บาท
                                                                    </div>
                                                                </div>
                                                                {/* <div className={"flex row"}>
                                                                    <div><label>ค่าบริการที่ APP ได้รับ</label></div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {parseFloat(plustime_app_price) > 0 ? Helper.numberFormat(plustime_app_price, { decimal: 2 }) : 0} บาท
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title" id="myModalLabel">ค่าบริการ</h4>
                                                    <div className="card-tools">
                                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                            <i className="fas fa-minus"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    {/* <div>
                                                        <div className={"flex row"}>
                                                            <div><label>ค่าบริการเรียกเก็บจาก สมาชิก</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {parseFloat(this.state.booking.price) > 0 ? Helper.numberFormat(this.state.booking.price, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ส่วนลด</label> {this.state.booking.promotion ? <>({this.state.booking.promotion.voucherCode})</> : null}</div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                -{parseFloat(total_discount) > 0 ? Helper.numberFormat(total_discount, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ค่าบริการหลังหักส่วนลด</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {parseFloat(this.state.booking.price) - parseFloat(this.state.booking.priceDiscount) > 0 ? Helper.numberFormat((this.state.booking.price - this.state.booking.priceDiscount), { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>อื่นๆ</label>
                                                                {
                                                                    this.state.booking.isHavePet || this.state.booking.isUseBeeClean ?
                                                                        <> ({this.state.booking.isHavePet ? "มีสัตว์เลี้ยง" : null}
                                                                            {this.state.booking.isHavePet && this.state.booking.isUseBeeClean ? "," : null}
                                                                            {this.state.booking.isUseBeeClean ? "ผลิตภัณฑ์จาก BeeClean" : null}
                                                                            )</>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(0, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ภาษี 7%</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {parseFloat(this.state.booking.chargePrice) > 0 ? Helper.numberFormat(this.state.booking.chargePrice, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>รวม</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {total_price > 0 ? Helper.numberFormat(total_price, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"divider"} /> */}
                                                    <div>
                                                        <div className={"flex row"}>
                                                            <div><label>ค่าบริการที่แม่บ้านได้รับ</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(this.state.booking.priceProviderWithoutAgent || this.state.booking.priceProvider, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.booking?.providerUser?.agent ?
                                                                <>
                                                                    <div className={"flex row"}>
                                                                        <div><label >ค่าคอมมิชชั่นนายหน้า <small>{Helper.numberFormat(this.state.booking.agentCommissionFromProviderPercentage)}%</small></label></div>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            {Helper.numberFormat((this.state.booking.priceAgentCommissionFromProvider), { decimal: 2 })} บาท
                                                                        </div>
                                                                    </div>
                                                                    <div className={"flex row"}>
                                                                        <div><label >ค่าคอมมิชชั่นนายหน้าจาก App <small>{Helper.numberFormat(this.state.booking.agentCommissionFromAppPercentage)}%</small></label></div>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            {Helper.numberFormat((this.state.booking.priceAgentCommissionFromApp), { decimal: 2 })} บาท
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : null
                                                        }
                                                        <div className={"flex row"}>
                                                            <div><label>อื่นๆ</label>
                                                                {
                                                                    this.state.booking.isHavePet || this.state.booking.isUseBeeClean ?
                                                                        <> ({this.state.booking.isHavePet ? "มีสัตว์เลี้ยง" : null}
                                                                            {this.state.booking.isHavePet && this.state.booking.isUseBeeClean ? "," : null}
                                                                            {this.state.booking.isUseBeeClean ? "ผลิตภัณฑ์จาก BeeClean" : null}
                                                                            )
                                                                        </>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(0, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>รวม</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat((this.state.booking.priceProvider + 0), { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label >รวมค่าคอมมิชชั่นที่ได้รับ</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat((this.state.booking.priceAgentCommissionFromProvider + this.state.booking.priceAgentCommissionFromApp), { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className={"divider"} />
                                                    <div>
                                                        <div className={"flex row"}>
                                                            <div><label>ส่วนต่างที่ APP ได้รับ</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {((this.state.booking.price - this.state.booking.priceDiscount) - this.state.booking.priceProvider) > 0 ? Helper.numberFormat(((this.state.booking.price - this.state.booking.priceDiscount) - this.state.booking.priceProvider), { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>อื่นๆ</label>
                                                                {
                                                                    this.state.booking.isHavePet || this.state.booking.isUseBeeClean ?
                                                                        <> ({this.state.booking.isHavePet ? "มีสัตว์เลี้ยง" : null}
                                                                            {this.state.booking.isHavePet && this.state.booking.isUseBeeClean ? "," : null}
                                                                            {this.state.booking.isUseBeeClean ? "ผลิตภัณฑ์จาก BeeClean" : null}
                                                                            )
                                                                        </>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {Helper.numberFormat(0, { decimal: 2 })} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>ภาษี 7% จากสมาชิก</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {this.state.booking.chargePrice > 0 ? Helper.numberFormat(this.state.booking.chargePrice, { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                        <div className={"flex row"}>
                                                            <div><label>รวม</label></div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {(((this.state.booking.price - this.state.booking.priceDiscount) - this.state.booking.priceProvider) + this.state.booking.chargePrice) > 0 ? Helper.numberFormat((((this.state.booking.price - this.state.booking.priceDiscount) - this.state.booking.priceProvider) + this.state.booking.chargePrice), { decimal: 2 }) : 0} บาท
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            {/* <ProviderPaymentCard
                                                payment={this.state.payment}
                                                booking={this.state.booking}
                                                summary={summary}
                                                onCheclOmiseSuccess={() => {
                                                    this.setState({
                                                        is_loading: true,
                                                    }, () => {
                                                        this.fetch();
                                                    })
                                                }}
                                            /> */}
                                        </div>
                                        <div className={"col-sm-12 col-lg-4"}>
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title" id="myModalLabel">ข้อมูลการรายงาน</h4>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        (this.state.booking.bookStatus === 'finish' && parseFloat(this.state.booking.starFromProvider) > 0 && this.state.booking.paymentStatus !== 'refund' && this.state.booking.paymentStatus !== 'reverce')
                                                            && this.state.booking.photoURLs ?
                                                            <>
                                                                <Slider {...settings} style={{ marginBottom: '1rem' }}>
                                                                    {
                                                                        this.state.booking.photoURLs.map((photoURL, photoURL_i) => (
                                                                            <div key={photoURL_i}>
                                                                                <a href={photoURL} data-fancybox={"gallery" + this.state.booking.id}>
                                                                                    <img src={photoURL} alt={"BeeCleanphotoURL"} style={{ maxWidth: '100%', height: 300, margin: 'auto' }} />
                                                                                </a>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </Slider>
                                                                <div className={"divider"} />
                                                            </>
                                                            : null
                                                    }
                                                    <div className={"flex row"}>
                                                        <div><label>ความประทับใจต่อลูกค้า</label></div>
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            <i className="fas fa-star" style={{ color: '#ffa200' }}></i> {this.state.booking && this.state.booking.starFromProvider !== undefined ? this.state.booking.starFromProvider.toFixed(1) : '0.0'}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div><label>รายงานจากแม่บ้าน</label> : {this.state.booking && this.state.booking.user_report && this.state.booking.user_report !== "" ? this.state.booking.user_report.report : '-'}</div>
                                                    </div>
                                                    <div className={"divider"} />
                                                    <div className={"flex row"}>
                                                        <div><label>ความประทับใจต่อแม่บ้าน</label></div>
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            <i className="fas fa-star" style={{ color: '#ffa200' }}></i> {this.state.booking && this.state.booking.starFromUser !== undefined ? this.state.booking.starFromUser.toFixed(1) : '0.0'}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div><label>รายงานจากสมาชิก</label> :  {this.state.booking && this.state.booking.provider_report && this.state.booking.provider_report !== "" ? this.state.booking.provider_report.report : '-'}</div>
                                                    </div>
                                                    {
                                                        this.state.booking.provider_report
                                                            && this.state.booking.provider_report.rateClean ?
                                                            <>
                                                                <div className={"divider"} />
                                                                <div className={"row"} >
                                                                    <div className={"col-lg-3"}>
                                                                        <div className={this.state.booking.provider_report.rateClean[0] ? "raing_icon check" : "raing_icon"}>
                                                                            <div className={"rating_circle"}>
                                                                                <img src={'/assets/images/clean.png'} alt={"Clean"} />
                                                                            </div>
                                                                            <div>
                                                                                Clean & Comfy
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={"col-lg-3"}>
                                                                        <div className={this.state.booking.provider_report.rateClean[1] ? "raing_icon check" : "raing_icon"}>
                                                                            <div className={"rating_circle"}>
                                                                                <img src={'/assets/images/women-icon.png'} alt={"Clean"} />
                                                                            </div>
                                                                            <div>
                                                                                Good Personality
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={"col-lg-3"}>
                                                                        <div className={this.state.booking.provider_report.rateClean[2] ? "raing_icon check" : "raing_icon"}>
                                                                            <div className={"rating_circle"}>
                                                                                <img src={'/assets/images/chat-icon.png'} alt={"Clean"} />
                                                                            </div>
                                                                            <div >
                                                                                Good Communicate
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={"col-lg-3"}>
                                                                        <div className={this.state.booking.provider_report.rateClean[3] ? "raing_icon check" : "raing_icon"}>
                                                                            <div className={"rating_circle"}>
                                                                                <img src={'/assets/images/award-icon.png'} alt={"Clean"} />
                                                                            </div>
                                                                            <div>
                                                                                Excellent Service
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                                    }

                                                </div>
                                            </div>
                                            {
                                                this.state.booking.plusTime
                                                    && this.state.booking.plusTime.length > 0 ?
                                                    <div className="card collapsed-card">
                                                        <div className="card-header" style={{ alignItems: 'center' }}>
                                                            <h4 className="card-title" id="myModalLabel">
                                                                ข้อมูลการเพิ่มเวลา
                                                            </h4>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            {
                                                                this.state.is_loading_time_plus ?
                                                                    <div style={{ padding: '1rem', textAlign: 'center', position: 'absolute', left: 0, top: 0, height: '100%', width: '100%' }}>
                                                                        <div className="ui active inline loader" ></div>
                                                                    </div>
                                                                    : null
                                                            }
                                                            <div className={"flex row"}>
                                                                <div style={{ width: '100%' }}>
                                                                    <div>
                                                                        <label>
                                                                            ผลรวมการเพิ่มเวลา
                                                                        </label>
                                                                    </div>
                                                                    <div className={"flex row"}>
                                                                        <div>
                                                                            <label>จำนวนชั่วโมงที่เพิ่ม</label> :  {' '}
                                                                            {hours} ชม.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className={"flex row"}>
                                                                <div><label>ค่าบริการที่แม่บ้านได้รับ</label></div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    ฿{Helper.numberFormat(summary.plustime_provider_only)}
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.booking.plusTime.map((plusTime, plusTime_i) => {
                                                                    if (plusTime.status === "wait") {
                                                                        wait_count++;
                                                                    }
                                                                    console.log(plusTime);
                                                                    return (
                                                                        <div className={"flex row"} key={plusTime_i}>
                                                                            <div className="divider"></div>
                                                                            <div style={{ width: '100%' }}>
                                                                                <div className={"flex row"} style={{ alignItems: 'center', marginBottom: '0.5rem' }}>
                                                                                    <label style={{ marginBottom: 0 }}>
                                                                                        {"เพิ่มชั่วโมงครั้งที่ " + (plusTime_i + 1)}
                                                                                    </label>

                                                                                    {
                                                                                        plusTime.status === "wait" ?
                                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                                <div style={{ marginBottom: 15 }}>
                                                                                                    <div style={{ textAlign: 'right' }}>฿{Helper.numberFormat(plusTime.priceTotal)} <small>ไม่รวมภาษี</small></div>
                                                                                                </div>
                                                                                            </div>
                                                                                            : null}
                                                                                </div>

                                                                                {
                                                                                    plusTime.name_th ?
                                                                                        <div className={"flex row"}>
                                                                                            <div>
                                                                                                <label>จำนวนชั่วโมง</label> :  {' '}
                                                                                                {plusTime.name_th}
                                                                                            </div>
                                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                                ฿{Helper.numberFormat(plusTime.price)}
                                                                                            </div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    plusTime.omise
                                                                                        && plusTime.omise.card ?
                                                                                        <>
                                                                                            <div>
                                                                                                <label>ประเภทบัตร</label> :  {' '}
                                                                                                {plusTime.omise.card.brand}
                                                                                            </div>
                                                                                            <div>
                                                                                                <label>เลขบัตร 4 ตัวสุดท้าย</label> :  {' '}
                                                                                                {plusTime.omise.card.last_digits}
                                                                                            </div>
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    plusTime.status ?
                                                                                        <div className="flex row">
                                                                                            <div>
                                                                                                <label>สถานะ</label> :  {' '}
                                                                                                {
                                                                                                    plusTime.status === 'wait' ?
                                                                                                        "รอชำระเงิน" : null
                                                                                                }
                                                                                                {
                                                                                                    plusTime.status === 'cancel' ?
                                                                                                        "ยกเลิก" : null
                                                                                                }
                                                                                                {
                                                                                                    plusTime.status === 'fail' ?
                                                                                                        "ชำระเงินไม่สำเร็จ" : null
                                                                                                }
                                                                                                {
                                                                                                    plusTime.status === 'success' ?
                                                                                                        "ชำระเงินสำเร็จ" : null
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                plusTime.paymentBy === "banktransfer"
                                                                                                    && plusTime.plusTimeImages
                                                                                                    && plusTime.plusTimeImages.length > 0 ?
                                                                                                    <>
                                                                                                        <span
                                                                                                            style={{ marginLeft: 'auto' }}
                                                                                                            className="link"
                                                                                                            href={plusTime.plusTimeImages[0].imageURL}
                                                                                                            data-fancybox={`plustime${plusTime_i}`}
                                                                                                        >หลักฐานการโอนเงิน {plusTime.plusTimeImages.length} รูป</span>
                                                                                                        {
                                                                                                            plusTime.plusTimeImages.map((image, image_i) => {
                                                                                                                if (image_i === 0) { return null }
                                                                                                                return (
                                                                                                                    <img style={{ display: 'none' }}
                                                                                                                        data-fancybox={`plustime${plusTime_i}`}
                                                                                                                        src={image.imageURL} alt={'plustime payment'} />
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    plusTime.omise
                                                                                        && plusTime.omise.authorize_uri ?
                                                                                        <div style={{ wordBreak: 'break-all' }}>
                                                                                            <label>Link omise URL</label> :  {' '}
                                                                                            {plusTime.omise.authorize_uri}
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    plusTime.updatedAt ?
                                                                                        <div>
                                                                                            <label>เวลาที่แอดมินทำรายการขึ้นไป</label> :  {' '}
                                                                                            {Helper.getDateThai(plusTime.updatedAt, { is_show_time: true })}
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                <div>
                                                                                    <label>หมายเหตุ</label> :  {' '}
                                                                                    การปรับเพิ่ม หรือ ลด รายได้ของแม่บ้านจะไม่รวมอยู่ในค่าคอมมิชชั่น
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                            {
                                                this.state.booking.providerSalary ?
                                                    <div className="card collapsed-card">
                                                        <div className="card-header" style={{ alignItems: 'center' }}>
                                                            <h4 className="card-title" id="myModalLabel">
                                                                หลักฐานรายได้แม่บ้าน
                                                            </h4>
                                                            <div className="card-tools">
                                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                                    <i className="fas fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            {
                                                                this.state.booking.providerSalary.images
                                                                    && this.state.booking.providerSalary.images.length > 0 ?
                                                                    <div className="row">
                                                                        {
                                                                            this.state.booking.providerSalary.images.map((image, image_i) => {
                                                                                return (

                                                                                    <div className={this.state.booking.providerSalary.images.length === 1 ? "col-12" : "col-6"} key={image_i}>
                                                                                        <a href={image.imageURL} data-fancybox={'slip'}>
                                                                                            <div className="card" style={{ marginBottom: 0 }}>
                                                                                                <div className="card-body" style={{ padding: '0.5rem' }}>
                                                                                                    <img src={image.imageURL} alt="Slip" style={{ maxWidth: '100%' }} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <div className="card-footer">
                                                            <div className="flex row">
                                                                {
                                                                    this.state.booking.providerSalary.salaryUid ?
                                                                        <div>
                                                                            <div>{this.state.booking.providerSalary.title}</div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    this.state.booking.providerSalary.updateProviderSalaryAdmin ?
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            {this.state.booking.providerSalary.updateProviderSalaryAdmin.displayName}
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>

                                                    </>
                                            }


                                        </div>
                                    </div>
                                </>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && !this.state.booking ?
                                <>
                                    <h4>ไม่มีข้อมูลหรือถูกลบไปแล้วโปรดติดต่อผู้ดูแลระบบ</h4>
                                </>
                                : null
                        }
                    </div>
                </section>
            </>
        );
    }
}

class BookingDetails extends React.Component {
    constructor() {
        super();
        this.state = {
            cancal_modal: false
        }
    }
    async wegateRefund(data) {
        let postData = { ...data };
        postData.bookUid = this.props.data.id;
        delete postData.storagePath;
        try {
            let res = await axios.post(`${window.api_host}/wegate-refund-booking`, postData);
            window.$(document).Toasts('create', {
                title: 'คืนเงิน Booking สำเร็จ',
                body: "ระบบได้ทำการยื่นขอคือเงิน Booking เรียบร้อยแล้ว",
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            if (this.props.onCancel) {
                this.props.onCancel();
            }
            this.$modal.modal('hide');
        } catch (error) {
            if (data.storagePath) {
                storage.ref(data.storagePath).delete();
            }
            if (error && error.data && error.data.message) {
                window.$(document).Toasts('create', {
                    title: 'คืนเงินไม่สำเร็จ',
                    body: error.data.message,
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            } else {
                window.$(document).Toasts('create', {
                    title: 'คืนเงินไม่สำเร็จ',
                    body: `กรุณาลองใหม่อีกครั้ง`,
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
            }
            if (this.$file) {
                let upload_btn = this.$file.parent().find('.btn');
                upload_btn.removeClass('loading');
            }
            this.$confim.removeClass('loading');
            if (this.props.onCancel) {
                this.props.onCancel();
            }
            this.$modal.modal('hide');
        }

    }
    cancelBooking() {
        let bookUid = this.props.data.id;
        axios.post(window.api_host + '/omise-refund', {
            bookUid
        }).then(async res => {
            let cancelRemark = this.$cancelRemark.val();
            if (res.data.code === 200) {
                await axios.put(`${window.api_host}/booking`, {
                    id: bookUid,
                    update: {
                        cancelRemark: cancelRemark
                    },
                });

                window.$(document).Toasts('create', {
                    title: 'ยกเลิก Booking สำเร็จ',
                    body: "ระบบได้ทำการยกเลิก Booking เรียบร้อยแล้ว",
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-success",
                })
                if (this.props.onCancel) {
                    this.props.onCancel();
                }
                this.$modal.modal('hide');
            } else {
                window.$(document).Toasts('create', {
                    title: 'เพิ่มข้อมูลไม่สำเร็จ',
                    body: res.data.message,
                    icon: 'fas fa-times',
                    autohide: true,
                    delay: 3000,
                    class: "bg-danger",
                })
                this.$confim.removeClass('loading');
            }
        }).catch(error => {
            this.$confim.removeClass('loading');
            window.$(document).Toasts('create', {
                title: 'เพิ่มข้อมูลไม่สำเร็จ',
                body: "กรุณาลองใหม่อีกครั้ง",
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
        });
    }

    readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.$file.parent().find('.button').text('เปลี่ยนรูป');
                window.$('#show_image').html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
                window.$('#show_image').html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
            };

            reader.readAsDataURL(input.files[0]);
        }
    }
    uploadFile(node, options) {
        // let id = options.PromotionId;
        let upload_btn = node.parent().find('.btn');
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        const filename = Helper.setNewImageFileName(file);
        console.log('before upload image', filename);
        var uploadTask = storage.ref().child('bookingRefund/' + filename).put(file);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log('uploading image', snapshot);
        }, (error) => {
            console.log('upload image error', error);
            Helper.messageTop({ message: 'อัพโหลดรูปไม่สำเร็จ' });
            upload_btn.removeClass('loading');
            this.$confim.removeClass('loading');
        }, async () => {
            let url = 'bookingRefund/' + filename;
            console.log('upload image success', url);
            if (options.onSuccess) {
                options.onSuccess(url);
            }

        });
    }

    render() {
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                }}
            >
                <div className="modal-dialog modal-md" role="document" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className={"form-group"}>
                                <label>หมายเหตุ</label>
                                <textarea name={"cancelRemark"} className={"form-control"} ref={(ref) => { this.$cancelRemark = window.$(ref) }}></textarea>
                            </div>
                            {
                                this.props.data.type == 'wegate' ?
                                    <>
                                        <div>
                                            <label>รูปภาพ</label>
                                            <input name="image"
                                                type={"file"}
                                                ref={(ref) => this.$file = window.$(ref)}
                                                style={{ display: 'none' }}
                                                id="file_image"
                                                onChange={(e) => {
                                                    this.readURL(this.$file[0]);
                                                }}
                                            />
                                            <div id="show_image" />
                                            <label htmlFor="file_image"
                                                type={"button"}
                                                className="btn btn-default"
                                                style={{ width: '100%' }}
                                            >
                                                อัพโหลด
                                            </label>
                                        </div>
                                    </>
                                    : null
                            }
                        </div>
                        <div className="modal-footer">
                            <div className={"flex row"}>
                                <div style={{ marginLeft: 'auto' }}>
                                    <button className={"btn btn-default"}
                                        onClick={() => {
                                            this.$modal.modal('hide')
                                        }}
                                    >ไม่ดำเนิการ</button>
                                    <button className={"btn btn-primary"}
                                        style={{ marginLeft: '1rem' }}
                                        ref={(ref) => {
                                            this.$confim = window.$(ref);
                                        }}
                                        onClick={() => {
                                            if (this.$confim.hasClass('loading')) {
                                                return;
                                            }
                                            this.$confim.addClass('loading');
                                            if (this.props.data.type == 'wegate') {
                                                let data = {};
                                                let cancelRemark = this.$cancelRemark.val();
                                                data.refundReason = cancelRemark;
                                                if (this.$file && this.$file[0] && this.$file[0].files[0]) {
                                                    this.uploadFile(this.$file, {
                                                        onSuccess: async (url) => {
                                                            data.storagePath = url;
                                                            data.slipURL = await storage.ref(url).getDownloadURL();
                                                            this.wegateRefund(data);
                                                        }
                                                    })
                                                    return;
                                                } else {
                                                    window.$(document).Toasts('create', {
                                                        title: 'คืนเงินไม่สำเร็จ',
                                                        body: "กรุณาอัพโหลดสลิป",
                                                        icon: 'fas fa-times',
                                                        autohide: true,
                                                        delay: 3000,
                                                        class: "bg-danger",
                                                    })
                                                    this.$confim.removeClass('loading');
                                                    return;
                                                }
                                            } else {
                                                this.cancelBooking();
                                            }
                                        }}
                                    >
                                        {
                                            this.props.data.type == 'wegate' ?
                                                `ดำเนินการคืนเงิน  Booking`
                                                : `ดำเนินการยกเลิก  Booking`
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        );
    }
}


class BookingDateTimeList extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            history: [],
        }
    }

    async componentDidMount() {
        let history = await Promise.all(
            this.props.data.map(async (_data) => {
                let admin = await (await db.doc('admin/' + _data.adminUid).get()).data();
                return {
                    ..._data,
                    admin: admin,
                }
            })
        )
        // console.log(history);
        this.setState({
            is_loading: false,
            history: history,
        })
    }

    render() {
        // console.log(this.state.history);
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        // console.log(data);
                        // return;
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                ประวัติการเปลี่ยนวันเวลาที่ให้บริการ
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            {
                                this.state.is_loading ?
                                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                                        <div className="ui active inline loader" ></div>
                                    </div>
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        {
                                            this.state.history.map((history, history_i) => {
                                                return (
                                                    <React.Fragment key={history_i}>
                                                        {history_i > 0 ? <div className="divider"></div> : null}
                                                        <div>
                                                            <label>ครั้งที่ : </label> {history_i + 1}
                                                        </div>
                                                        <div className="flex row" >
                                                            <div>

                                                                <div>
                                                                    <label>เวลาเดิม : </label> {Helper.getDateThai(history.currentBookAt, { is_show_time: true })}
                                                                </div>
                                                                <div>
                                                                    <label>เวลาใหม่ : </label> {Helper.getDateThai(history.updateBookedAt, { is_show_time: true })}
                                                                </div>
                                                                {
                                                                    history.note ?
                                                                        <div>
                                                                            <label>หมายเหตุ : </label> {history.note}
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                <div style={{ textAlign: 'right' }}><label>แก้ไขโดย : </label> {history.admin.displayName}</div>
                                                                <div style={{ textAlign: 'right' }}>{Helper.getDateThai(history.updatedAt, { is_show_time: true })}</div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })
                                        }
                                    </>
                                    : null
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ปิด</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}