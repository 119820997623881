import React from 'react';
import { Link } from '@reach/router';
import { Helper } from './../Helper';

export default class Pagination extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            now_page: props.now,
            all_items: props.all_items || 0,
            limit: props.limit,
            location: props.location,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.now !== this.props.now
            || prevProps.all_items !== this.props.all_items
            || prevProps.limit !== this.props.limit
        ) {
            this.setState({
                now_page: this.props.now,
                all_items: this.props.all_items,
                limit: this.props.limit,
                location: this.props.location
            })
        }
    }
    getPagination() {
        let _pagination = [];
        let i;
        if (this.props.all_items > 0) {
            let x = this.props.all_items / this.props.limit;
            for (i = 0; i < x; i++) {
                _pagination.push(i + 1);
            }
        } else {
            _pagination.push(1);
        }
        return _pagination;
    }
    render() {
        if (this.props.all_items === 0) {
            return null;
        }
        let _param_next = null;
        if (this.state.location && this.state.location.search) {
            _param_next = this.state.location.search;
            _param_next = _param_next.replace('?', '');
            if (this.state.now_page) {
                _param_next = _param_next.replace('&page=' + parseFloat(this.state.now_page), '');
                _param_next = _param_next.replace('page=' + parseFloat(this.state.now_page), '');
            }
            _param_next = _param_next + '&page=' + (parseFloat(this.state.now_page) + 1);
        }
        if (!this.state.location || !this.state.location.search) {
            _param_next = 'page=' + (parseFloat(this.state.now_page) + 1);
        }



        let _param_prev = null;
        if (this.state.location && this.state.location.search) {
            _param_prev = this.state.location.search;
            _param_prev = _param_prev.replace('?', '');
            if (this.state.now_page) {
                _param_prev = _param_prev.replace('&page=' + this.state.now_page, '');
                _param_prev = _param_prev.replace('page=' + this.state.now_page, '');
            }
            _param_prev = _param_prev + '&page=' + (parseFloat(this.state.now_page) - 1);
        }
        if (!this.state.location || !this.state.location.search) {
            _param_prev = 'page=' + (parseFloat(this.state.now_page) - 1);
        }

        let is_show_dot = 1; //จำนวนที่แสดงไม่รวมลำดับที่เลือกอยู่
        return (
            <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                    <div className="pagination m-0">
                        {
                            parseFloat(this.state.now_page) === 1 ?
                                <div className={"page-item"}>
                                    <div className={"page-link disabled"}>
                                        <i className="fas fa-chevron-left"></i>
                                    </div>
                                </div>
                                :
                                <div className={"page-item"}>
                                    {
                                        this.props.onReturnPage ?
                                            <div className={"page-link"}
                                                onClick={() => {
                                                    this.props.onReturnPage(parseFloat(this.state.now_page) - 1);
                                                }}
                                            >
                                                <i className="fas fa-chevron-left"></i>
                                            </div>
                                            :
                                            <Link to={this.props.location.pathname + '?' + _param_prev}
                                                className={"page-link"}>
                                                <i className="fas fa-chevron-left"></i>
                                            </Link>
                                    }
                                </div>
                        }
                        {
                            this.getPagination().map((pagi, pagi_index) => {
                                let param = null;
                                if (this.state.location && this.state.location.search) {
                                    param = this.state.location.search;
                                    param = param.replace('?', '');
                                    if (this.state.now_page) {
                                        param = param.replace('&page=' + this.state.now_page, '');
                                        param = param.replace('page=' + this.state.now_page, '');
                                    }
                                    param = param + '&page=' + pagi;
                                }
                                if (!this.state.location || !this.state.location.search) {
                                    param = 'page=' + pagi;
                                }

                                if ((!this.state.now_page && parseFloat(pagi) <= is_show_dot + 1)
                                    || (!this.state.now_page && parseFloat(pagi) === parseFloat(this.getPagination().length))
                                    || (parseFloat(this.state.now_page) === parseFloat(pagi))
                                    || (
                                        (parseFloat(this.state.now_page) - is_show_dot <= parseFloat(pagi))
                                        && (parseFloat(this.state.now_page) + is_show_dot >= parseFloat(pagi))
                                    )
                                    || (parseFloat(this.state.now_page) >= is_show_dot && parseFloat(pagi) === 1) //โชว์ 1 ตลอด
                                    || (parseFloat(pagi) === parseFloat(this.getPagination().length)) // โชว์ เลขสุดท้ายตลอด
                                ) {
                                    return (
                                        <React.Fragment key={pagi_index}>
                                            {
                                                this.state.now_page
                                                    && parseFloat(this.state.now_page) >= (is_show_dot + 3)
                                                    && (
                                                        parseFloat(this.state.now_page) - (is_show_dot) === parseFloat(pagi)
                                                    ) ?
                                                    <div className={"page-item"}>
                                                        <div className={"page-link disabled"}>
                                                            ...
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                parseFloat(this.props.now) === parseFloat(pagi) ?
                                                    <div className={"page-item"}>
                                                        <div className={"page-link disabled"}>
                                                            {pagi}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className={"page-item"}>
                                                        {
                                                            this.props.onReturnPage ?
                                                                <div className={"page-link"}
                                                                    onClick={() => {
                                                                        this.props.onReturnPage(pagi);
                                                                    }}
                                                                >
                                                                    {pagi}
                                                                </div>
                                                                :
                                                                <Link to={this.props.location.pathname + '?' + param}
                                                                    className={"page-link"}>
                                                                    {pagi}
                                                                </Link>
                                                        }
                                                    </div>
                                            }

                                            {
                                                (
                                                    (!this.state.now_page && parseFloat(pagi) === is_show_dot + 1)
                                                    && parseFloat(this.getPagination().length) > (is_show_dot + 1)
                                                )
                                                    || ((parseFloat(this.state.now_page) + is_show_dot === parseFloat(pagi))
                                                        && parseFloat(this.state.now_page) < this.getPagination().length - (is_show_dot + 1)) ?
                                                    <div className={"page-item"}>
                                                        <div className={"page-link disabled"}>
                                                            ...
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </React.Fragment>
                                    );
                                }
                                return false;
                            })
                        }
                        {
                            parseFloat(this.state.now_page) === this.getPagination().length || this.getPagination().length === 1 ?
                                <div className={"page-item"}>
                                    <div className={"page-link disabled"}>
                                        <i className="fas fa-chevron-right"></i>
                                    </div>
                                </div>
                                :
                                <div className={"page-item"}>
                                    {
                                        this.props.onReturnPage ?
                                            <div className={"page-link"}
                                                onClick={() => {
                                                    this.props.onReturnPage(parseFloat(this.state.now_page) + 1);
                                                }}
                                            >
                                                <i className="fas fa-chevron-right"></i>
                                            </div>
                                            :
                                            <Link to={this.props.location.pathname + '?' + _param_next}
                                                className={"page-link"}
                                            >
                                                <i className="fas fa-chevron-right"></i>
                                            </Link>
                                    }

                                </div>
                        }
                    </div>
                </div>
                <div className="flex row flex-center right-floated" style={{ marginLeft: 'auto' }}>
                    {
                        (this.state.now_page * this.state.limit) > this.state.all_items ?
                            this.state.all_items : !this.state.all_items ? 0 : (this.state.now_page * this.state.limit)
                    }/
                    {this.state.all_items || 0}
                </div>
            </div >
        );
    }
}