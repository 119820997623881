import httpClient, { serverUrl } from './HttpClient';
const _serverUrl = serverUrl;

const baseUrl = `${_serverUrl}`;

export const agentService = {
    get: async (agentUid, timeRange, month, year) => {
        const url = `${baseUrl}/salary?agentUid=${agentUid}&timeRange=${timeRange}&month=${month}&year=${year}`;
        try {
            const res = await httpClient.get(url)
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    changePassword: async (agentUid, oldPassword, newPassword, confirmNewPassword) => {
        const url = `${baseUrl}/agent-change-password`;
        try {
            const res = await httpClient.post(url, { agentUid, oldPassword, newPassword, confirmNewPassword })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    emailResetPassword: async (email) => {
        const url = `${baseUrl}/agent-email-reset-password`;
        try {
            const res = await httpClient.post(url, { email })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    getMailResetPassword: async (agentUid, key) => {
        const url = `${baseUrl}/agent-get-change-password`;
        try {
            const res = await httpClient.post(url, { agentUid, key })
            return res.data;
        } catch (error) {
            return { error: true, ...error };
        }
    },
    //create and update use same api
    // update: async ({ uid, bank, bankBranch, bankAccountName, bankAccountNo }) => {
    //     const url = `${baseUrl}`;
    //     try {
    //         const res = await httpClient.post(url, { agentUid: uid, bank, bankBranch, bankAccountName, bankAccountNo })
    //         return res.data;
    //     } catch (error) {
    //         return { error: true, ...error };
    //     }
    // },
}