import React, { useState, useContext } from "react";
import { Link, navigate } from "@reach/router";
import { Helper } from "../Helper";
import { agentService } from "../Services/agentService";
const axios = require('axios').default;

export default class PasswordReset extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            newPassword: false,
        }
    }

    componentDidMount() {
        this.callApi();
    }

    async callApi() {
        let key = Helper.getParameterByName('key') || false;
        console.log(this.props.agentUid, key)

        const res = await agentService.getMailResetPassword(this.props.agentUid, key);
        if (res.error) {
            this.setState({
                is_loading: false,
                newPassword: res.response.data.message
            })
            return;
        }

        this.setState({
            is_loading: false,
            newPassword: res.password
        })
    }


    render() {
        return (
            <div className={"hold-transition login-page login_background"}>
                <div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
                <div className="login-box" style={{ marginTop: '-15rem' }}>
                    <div className="card">
                        <div className="login-logo">
                            <img src={`${window.location.origin}/assets/images/logo.png`} alt="Beeclean Logo" style={{ maxHeight: 150 }} />
                        </div>
                        <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0, marginTop: -20 }}>AGENT</h3>
                        <div className="card-body login-card-body">
                            <p className="login-box-msg mb-3 pb-0">รหัสใหม่ของคุณคือ</p>

                            <form ref={(ref) => {
                                this.$form = window.$(ref)
                            }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (this.$submitBtn.hasClass('loading')) { return; }
                                    this.$submitBtn.addClass('loading')
                                    let data = {};
                                    this.$form.serializeArray().map((item) => {
                                        data[item.name] = item.value;
                                        return true
                                    })
                                    this.sendResetEmail(data.email)
                                }}
                            >
                                {
                                    this.state.is_loading ?
                                        <div style={{ padding: '1rem', textAlign: 'center' }}>
                                            <div className="ui active inline loader" ></div>
                                        </div>
                                        : null
                                }
                                {
                                    !this.state.is_loading
                                        && this.state.newPassword ?
                                        <div className="alert alert-success">
                                            <h2 className="mb-0 text-center">{this.state.newPassword}</h2>
                                        </div>
                                        : null
                                }

                                <div className="d-flex justify-content-center">
                                    <Link
                                        to="/"
                                        className="my-2 text-blue-700 hover:text-blue-800 text-center block"
                                    >
                                        กลับ
                                    </Link>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}