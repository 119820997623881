import React, { useContext, useEffect } from "react";
import { Router } from "@reach/router";
import { UserContext } from "../providers/UserProvider";

import SignIn from "./SignIn";
import AgentUpdateInformation from "./AgentUpdateInformation";

import Header from "./../Include/Header";
import SideBar from "./../Include/SideBar";
import Dashboard from "./../Page/Dashboard";
import Books from './../Page/Books/List'
import BooksView from './../Page/Books/View'

import Maids from './../Page/Maids/List'
import MaidsView from './../Page/Maids/View'

import Profile from './../Page/Profile'
import Salary from './../Page/Salary'
import PasswordReset from "./PasswordReset";
import EmailChangePassword from "./EmailChangePassword";


window.months_th = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม",];
window.segment = window.location.pathname.split('/');
window.api_host = 'https://asia-southeast2-beeclean-app-66b97.cloudfunctions.net/app';
window.api_host_agent = 'https://asia-southeast2-beeclean-app-66b97.cloudfunctions.net/agent';

let is_emulators = false;
if (window.location.origin.indexOf('localhost:') !== -1) {
  is_emulators = true;
}
if (is_emulators) {
  window.api_host = "http://localhost:5001/beeclean-app-66b97/asia-southeast2/app";
  window.api_host_agent = 'http://localhost:5001/beeclean-app-66b97/asia-southeast2/agent';
}
//  window.api_host = "http://localhost:5001/beeclean-app/asia-southeast2/app";
function Application() {
  const user = useContext(UserContext);
  // const [isAPI, setIsAPI] = useState(false);
  useEffect(() => {

    // window.$("head").find('style').remove();
  }, []);

  return (
    user
      && user.isAgentUpdatedInformation
      ?
      <div className={"hold-transition sidebar-mini"}>
        <div className="wrapper">
          <Header user={user} />
          <SideBar user={user} />
          <Router className="content-wrapper">
            <Dashboard path="/" agent={user} uid={user.uid} />
            <Dashboard path="/dashboard" agent={user} uid={user.uid} />
            <Books path="/books" agent={user} />
            <BooksView path="/book/:booking_id" agent={user} />
            <Maids path="/maids" agent={user} />
            <MaidsView path="/maid/:provider_id" agent={user} />
            <Salary path="/salary" agent={user} uid={user.uid} />
            <Profile path="/profile" agent={user} uid={user.uid} />
          </Router>
        </div>
      </div>
      :
      user && !user.isAgentUpdatedInformation ?
        <Router>
          <AgentUpdateInformation path="/" agent={user} />
        </Router>
        :
        <Router>
          <SignIn path="/" />
          <PasswordReset path="/forgot-password" />
          <EmailChangePassword path="/email-change-password/:agentUid" />
        </Router>
  );
}

export default Application;
