import React from 'react';
import { Helper } from '../Helper';
const axios = require('axios').default;

export default class ProviderPaymentCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payment: props.payment,
            booking: props.booking,
        }
    }
    async checkOmise() {
        try {
            let res = await axios.post(`${window.api_host}/omise-charge-check`, { bookUid: this.props.booking.id });
            this.checkOmiseRef.removeClass('loading');

            window.$(document).Toasts('create', {
                title: 'ตรวจสอบสำเร็จ',
                // body: 'ยกเลิกการชำระเงินเรียบร้อยแล้ว',
                icon: 'fas fa-check',
                autohide: true,
                delay: 3000,
                class: "bg-success",
            })
            this.props.onCheclOmiseSuccess();
        } catch (error) {
            Helper.messageTop({ message: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง' })
            this.checkOmiseRef.removeClass('loading')
        }
    }
    render() {
        if (!this.props.payment) { return null; }
        let summary = this.props.summary;
        let plustime_price = 0;
        let plustime_total_price = 0;
        let plustime_app_price = 0;
        let plustime_provider_price = 0;
        let hours = 0;
        let total_price = 0;
        let wait_count = 0;
        let total_discount = 0;
        if (summary) {
            plustime_price = summary.plustime_price;
            plustime_total_price = summary.plustime_total_price;
            plustime_app_price = summary.plustime_app_price;
            plustime_provider_price = summary.plustime_provider_price;
            hours = summary.hours;
            total_price = summary.total_price;
            wait_count = 0;
            total_discount = summary.total_discount;

        }
        console.log(this.props.booking)
        return (
            <div className="card">
                <div className="card-header" style={{ alignItems: 'center' }}>
                    <h4 className="card-title" id="myModalLabel">
                        การชำระเงิน
                    </h4>
                    {
                        this.props.disableButton ? null :
                            <div className="card-tools">
                                {
                                    this.props.booking.channel == 'omise'
                                        && this.props.onCheclOmiseSuccess ?
                                        <button
                                            ref={(ref) => {
                                                this.checkOmiseRef = window.$(ref);
                                            }}
                                            className='btn btn-default btn-sm mr-2'
                                            onClick={() => {
                                                if (this.checkOmiseRef.hasClass('loading')) { return; }
                                                this.checkOmiseRef.addClass('loading')
                                                this.checkOmise();
                                            }}
                                        >ตรวจสอบสถานะ omise</button>
                                        : null
                                }
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                    }
                </div>
                <div className="card-body">
                    <>
                        <div className={"flex row"}>
                            <div>
                                <label>สถานะ</label> :  {' '}
                                {this.renderPaymentStatus()}
                                {
                                    this.props.payment
                                        && this.props.payment.omise_id
                                        && !this.props.payment.paid
                                        && this.props.payment.status == 'success' ?
                                        <small className='badge bg-danger'>Please check omise</small>
                                        : null
                                }
                            </div>

                        </div>
                        <div>
                            <label>เมื่อเวลา</label> :  {' '}
                            {
                                this.props.booking.paymentBy
                                    && this.props.booking.paymentBy == 'banktransfer' ?
                                    Helper.getDateThai(this.props.booking.createdAt, { is_show_time: true }) + ' น.'
                                    : this.props.payment.omise ?
                                        Helper.getDateThai(this.props.payment.omise.created_at, { is_show_time: true }) + ' น.'
                                        : this.props.payment.type == 'wegate' && this.props.payment.paymentAt ?
                                            Helper.getDateThai(this.props.payment.paymentAt, { is_show_time: true }) + ' น.'
                                            : this.props.payment.type == 'wegate' && !this.props.payment.paymentAt ?
                                                Helper.getDateThai(this.props.payment.updatedAt, { is_show_time: true }) + ' น.'
                                                : '-'
                            }
                        </div>
                        {
                            this.props.booking.channel
                                && (
                                    this.props.booking.paymentStatus == 'refund'
                                    || this.props.booking.paymentStatus == 'cancel'
                                ) ?
                                <>
                                    {
                                        this.props.booking.refundSlipURL ?
                                            <>
                                                <div className='mb-2'>
                                                    <img src={this.props.booking.refundSlipURL} alt={'refundSlipURL'} style={{ maxWidth: '100%' }} />
                                                </div>
                                                {
                                                    this.props.booking.refundReason ?
                                                        <div>
                                                            <label>หมายเหตุ</label> : {this.props.booking.refundReason}
                                                        </div>
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                </>
                                : null
                        }
                        <>
                            <div>
                                <label>ช่องทาง</label> :  {' '}
                                {
                                    this.props.payment.card ?
                                        "บัตรเครดิต"
                                        : null
                                }
                                {
                                    plustime_total_price == 0 &&
                                        !this.props.payment.omise_id
                                        && (!this.props.booking.paymentBy
                                            && this.props.booking.paymentBy !== 'banktransfer'
                                            && this.props.booking.type !== 'wegate') ?
                                        "ได้รับการยกเว้นชำระเงิน เนื่องจากโปรโมชั่นที่ใช้ เกินค่าบริการ"
                                        : null
                                }
                                {
                                    plustime_total_price > 0 &&
                                        !this.props.payment.omise_id
                                        && (!this.props.booking.paymentBy
                                            && this.props.booking.paymentBy !== 'banktransfer'
                                            && this.props.booking.type !== 'wegate') ?
                                        "ได้รับการยกเว้นชำระเงิน เนื่องจากโปรโมชั่นที่ใช้ เกินค่าบริการ (จำนวนเงินไม่ถูกต้อง)"
                                        : null
                                }
                                {
                                    !this.props.payment.omise_id
                                        && this.props.booking.paymentBy
                                        && this.props.booking.paymentBy === 'banktransfer' ?
                                        "โอนเงินผ่านธนาคาร"
                                        : null
                                }
                                {
                                    this.props.payment.type
                                        && this.props.payment.type == 'wegate' ?
                                        <>
                                            <div style={{ display: 'inline-block', verticalAlign: 'center' }}>
                                                {this.renderWeGatePaymentChannel(this.props.payment.channel)}

                                            </div>
                                        </>
                                        : null
                                }
                            </div>
                            {
                                this.props.booking.slipImageURL ?
                                    <>
                                        <div>
                                            <img src={this.props.booking.slipImageURL} alt={'transferSlip'} style={{ maxWidth: '100%' }} />
                                        </div>
                                        {
                                            this.props.booking.transferNote ?
                                                <div>
                                                    <label>หมายเหตุ</label> : {this.props.booking.transferNote}
                                                </div>
                                                : null
                                        }
                                    </>
                                    : null
                            }
                        </>
                        {
                            this.props.payment
                                && this.props.payment.card ?
                                <>

                                    <div>
                                        <label>ประเภทบัตร</label> :  {' '}
                                        {
                                            this.props.payment.card
                                                && this.props.payment.card.brand ?
                                                this.props.payment.card.brand
                                                : null
                                        }
                                    </div>
                                    <div>
                                        <label>เลขลงท้าย</label> :  {' '}
                                        {
                                            this.props.payment.card
                                                && this.props.payment.card.last_digits ?
                                                this.props.payment.card.last_digits
                                                : null
                                        }
                                    </div>
                                </>
                                : null
                        }
                        {
                            this.props.booking.paymentStatus === 'refund' ?
                                <>
                                    {
                                        this.props.payment.refundStatus ?
                                            <div>
                                                <label>สถานะคืนเงิน</label> :  {' '}
                                                {
                                                    this.props.payment.refundStatus === "successful" || this.props.payment.refundStatus === "reversed" ?
                                                        "สำเร็จ" : "ไม่สำเร็จ"
                                                }
                                            </div>
                                            : null
                                    }
                                    {/* <div>
                                    <label>Booking ID</label> :  {' '}
                                    {this.props.booking.id}
                                </div> */}
                                </>
                                : null
                        }

                        {
                            this.props.payment.type == 'wegate'
                                && this.props.payment.channel ?
                                <div>
                                    <label>Agent</label> : Ksher
                                    {
                                        this.props.payment.type
                                            && this.props.payment.type === 'wegate' ?
                                            <> <small className='badge bg-info'>We Payment Gateway</small></> : null
                                    }
                                </div>
                                : null
                        }
                        {
                            this.props.booking.paymentCode ?
                                <>
                                    <div>
                                        <label>Payment Code</label> : {this.props.booking.paymentCode}
                                    </div>
                                </>
                                : null
                        }
                        {
                            this.props.payment.omise_id ?
                                <div>
                                    <label>Omise Charge ID</label> :  {' '}
                                    {this.props.payment.omise_id}
                                </div>
                                : null
                        }
                    </>
                </div>
            </div>
        )
    }
    renderPaymentStatus() {
        if (this.props.payment.status === "refund"
            || this.props.booking.paymentStatus === "reverse"
            || this.props.payment.refundStatus === "reversed") {
            return <span className={"text-danger"}>คืนเงิน</span>
        } else if (this.props.payment.status === "successful"
            || this.props.payment.status === "success"
            || this.props.payment.status === "finish") {
            return <span className={"text-success"}>สำเร็จ</span>
        }
        else if (this.props.payment.status === "pending") {
            return <span className={"text-warning"}>รอดำเนินการ</span>
        }
        else if (this.props.payment.status === "waitPayment"
            || this.props.payment.status === "wait"
            || this.props.booking.paymentStatus == 'wait') {
            return <span className={"text-warning"}>รอการชำระเงิน</span>
        }
        else if (this.props.payment.status === "fail") {
            return <span className={"text-danger"}>ไม่สำเร็จ</span>
        }
        else if (this.props.payment.status === "cancel") {
            return <span className={"text-danger"}>ยกเลิก</span>
        }

    }
    renderWeGatePaymentChannel(channel) {
        console.log(channel)
        if (channel == 'PP') {
            return <><img style={{ ...paymentImage }} src={`${window.location.origin}/assets/images/channels/pp.png`} />PromptPay</>
        }
        if (channel == 'TM') {
            return <><img style={{ ...paymentImage }} src={`${window.location.origin}/assets/images/channels/tm.png`} />True Money</>
        }
        if (channel == 'AL') {
            return <><img style={{ ...paymentImage }} src={`${window.location.origin}/assets/images/channels/al.png`} />Alipay</>
        }
        if (channel == 'SP') {
            return <><img style={{ ...paymentImage }} src={`${window.location.origin}/assets/images/channels/sp.png`} />ShopeePay</>
        }
        if (channel == 'LP') {
            return <><img style={{ ...paymentImage }} src={`${window.location.origin}/assets/images/channels/lp.png`} />LINE Pay</>
        }
        if (channel == 'WC') {
            return <><img style={{ ...paymentImage }} src={`${window.location.origin}/assets/images/channels/wc.png`} />WeChat Pay</>
        }
    }
}
const paymentImage = {
    width: 35,
    height: 35,
    marginRight: 3
}