import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Helper } from '../Helper';
import { BankForm } from '../Components/BankForm';
import { agentService } from '../Services/agentService';

const axios = require('axios').default;

export default class Profile extends Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            data: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let res = await axios.get(`${window.api_host_agent}/agents/${this.props.agent.uid}`);
        // console.log(res)
        let data = false;
        if (res.data.success) {
            data = res.data.agent
        }
        this.setState({
            data: data,
            is_loading: false
        }, () => {
            let getcookiedata = Helper.getCookie('user') && Helper.getCookie('user') !== '' ? JSON.parse(Helper.getCookie('user')) : false;
            let __date = { ...getcookiedata, ...this.state.data }
            Helper.setCookie('user', JSON.stringify(__date), 1);
        })
    }

    render() {
        let agent = this.state.data;
        let totalCommission = agent?.wallet || 0;
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">ตัวแทน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item active">ข้อมูลตัวแทน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        {
                            this.state.is_loading ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }

                        {
                            !this.state.is_loading
                                && this.state.data ?
                                <>
                                    <div className='row'>
                                        <div className='col-12 col-md-3'>
                                            <div className="card">
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h3 className="card-title">
                                                        ข้อมูลตัวแทน
                                                    </h3>
                                                </div>
                                                <div className="card-body">
                                                    <div>
                                                        <label>ชื่อ</label> : {agent?.name}
                                                    </div>
                                                    {
                                                        agent.isAgentUpdatedInformation ?
                                                            <>
                                                                <div>
                                                                    <label>เลขบัตรประจำตัวประชาชน</label> : {agent?.personalNo}
                                                                </div>
                                                                <div>
                                                                    <label>ชื่อจริง</label> : {agent?.prefix} {agent?.firstName} {agent?.lastName}
                                                                </div>
                                                                <div>
                                                                    <label>เพศ</label> : {agent?.gender}
                                                                </div>
                                                                <div>
                                                                    <label>เบอร์โทรศัพท์ฉุกเฉิน</label> : {agent?.phoneNumber}
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                    <div>
                                                        <label>อีเมล</label> :  {agent?.email}
                                                    </div>
                                                    <div>
                                                        <label className='mb-2'>เบอร์โทรศัพท์ติดต่อ</label> :  {agent?.tel}
                                                    </div>
                                                    {/* <a target='_blank'
                                                        href={`https://beeclean-frontend.web.app/register?agent=${this.props.uid}`}
                                                        className='btn btn-default'
                                                    >
                                                        <i className='fas fa-link'/> สมัครแม่บ้าน
                                                    </a> */}
                                                    <button
                                                        className='btn btn-default'
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(`https://beeclean-frontend.web.app/register?agent=${this.props.uid}`);

                                                            // Alert the copied text
                                                            window.$(document).Toasts('create', {
                                                                title: 'คิดลอกลิ้งค์แล้ว',
                                                                icon: 'fas fa-clone',
                                                                autohide: true,
                                                                delay: 3000,
                                                                class: "bg-success",
                                                            })
                                                            // alert("Copied link: " + `https://beeclean-frontend.web.app/register?agent=${this.props.uid}`);
                                                        }}
                                                    >
                                                        <i className='fas fa-link' /> ลิงค์สมัครแม่บ้าน
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                agent.bank ?
                                                    <div className='card'>
                                                        <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <h3 className="card-title">
                                                                ข้อมูลธนาคาร
                                                            </h3>
                                                            <div className='card-tools' style={{ marginLeft: 'auto' }}>
                                                                <button
                                                                    ref={(ref) => { this.editBankBtn = window.$(ref) }}
                                                                    className={`btn btn-default btn-sm`}
                                                                    onClick={() => {
                                                                        Helper.reactToDom(window.$('body'),
                                                                            <BankForm
                                                                                uid={this.props.uid}
                                                                                agent={agent}
                                                                                onSuccess={(data) => {
                                                                                    let _data = { ...this.state.data, ...data };
                                                                                    this.setState({
                                                                                        data: _data,
                                                                                    })
                                                                                }}
                                                                            />
                                                                        )
                                                                    }}
                                                                >
                                                                    แก้ไขข้อมูล
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='card-body'>
                                                            <>
                                                                <div>
                                                                    <label>ธนาคาร</label> :  {agent.bank}
                                                                </div>
                                                                <div>
                                                                    <label>สาขา</label> :  {agent.bankBranch}
                                                                </div>
                                                                <div>
                                                                    <label>ชื่อบัญชี</label> :  {agent.bankAccountName}
                                                                </div>
                                                                <div>
                                                                    <label>เลขบัญชี</label> :  {agent.bankAccountNo}
                                                                </div>
                                                            </>
                                                        </div>
                                                    </div>
                                                    :
                                                    <button
                                                        ref={(ref) => { this.addBankBtn = window.$(ref) }}
                                                        className={`btn btn-primary`}
                                                        onClick={() => {
                                                            Helper.reactToDom(window.$('body'),
                                                                <BankForm
                                                                    uid={this.props.uid}
                                                                    agent={agent}
                                                                    onSuccess={(data) => {
                                                                        let _data = { ...this.state.data, ...data };
                                                                        this.setState({
                                                                            data: _data,
                                                                        })
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    >
                                                        เพิ่มข้อมูลธนาคาร
                                                    </button>
                                            }
                                            {
                                                agent.isAgentUpdatedInformation ?

                                                    <div className='card'>
                                                        <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <h3 className="card-title">
                                                                ข้อมูลที่อยู่
                                                            </h3>
                                                        </div>
                                                        <div className='card-body'>
                                                            <>
                                                                <div>
                                                                    <label>ที่อยู่ตามบัตรประชาชน</label> :  {agent.address}
                                                                </div>
                                                                <div>
                                                                    <label>เลขไปรษณีย์</label> :  {agent.zipcode}
                                                                </div>
                                                                <hr style={{ marginTop: 0 }} />
                                                                <div>
                                                                    <label>ที่อยู่จัดส่งเอกสาร</label> :  {agent.deliveryAddress}
                                                                </div>
                                                                <div>
                                                                    <label>เลขไปรษณีย์</label> :  {agent.deliveryZipcode}
                                                                </div>
                                                            </>
                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                        </div>
                                        <div className='col-12 col-md-9'>
                                            <div className='row'>
                                                <div className='col-12 col-md-5'>
                                                    <div className="info-box mb-3">
                                                        <span className={`info-box-icon ${totalCommission > 0 ?
                                                            "bg-success"
                                                            : totalCommission < 0 ?
                                                                "bg-danger"
                                                                :
                                                                "bg-info"
                                                            } elevation-1`}>
                                                            {
                                                                totalCommission > 0 ?
                                                                    <i className="far fa-thumbs-up"></i>
                                                                    : totalCommission < 0 ?
                                                                        <i className="far fa-thumbs-down"></i>
                                                                        :
                                                                        <i className="fas fa-gem"></i>
                                                            }
                                                        </span>
                                                        <div className="info-box-content">
                                                            <span className="info-box-text text-muted">ค่าคอมมิชชั่น</span>
                                                            <span className="info-box-number" style={{ fontWeight: 'bold', fontSize: 22 }}>฿ {Helper.numberFormat(totalCommission, { decimal: 2 })}</span>
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <Link to={`/salary`} style={{ marginLeft: 10 }}>
                                                                <button className="btn btn-default">รายละเอียด</button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className='card'>
                                                        <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <h3 className="card-title">
                                                                ข้อมูลค่าคอมมิชั่น
                                                            </h3>
                                                        </div>
                                                        <div className='card-body'>
                                                            <>
                                                                <div>
                                                                    <label>ค่าคอมมิชชั่นจากแม่บ้าน</label> :  {agent.agentCommissionRateProvider || 0}%
                                                                </div>
                                                                <div>
                                                                    <label>ค่าคอมมิชชั่นจาก APP</label> :  {agent.agentCommissionRateApp || 0}%
                                                                </div>
                                                            </>
                                                        </div>
                                                    </div>
                                                    {
                                                        agent.isAgentUpdatedInformation ?

                                                            <div className='card'>
                                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <h3 className="card-title">
                                                                        ข้อมูลรูป
                                                                    </h3>
                                                                </div>
                                                                <div className='card-body'>
                                                                    <div className='row'>
                                                                        {
                                                                            agent.images.map((image, i) => {
                                                                                let title = false;
                                                                                if (image.inputName == 'profileImage') {
                                                                                    title = "รูปโปรไฟล์"
                                                                                }
                                                                                if (image.inputName == 'personalCardImage') {
                                                                                    title = "รูปสำเนาบัตรประชาชน"
                                                                                }
                                                                                if (image.inputName == 'bookBankImage') {
                                                                                    title = "รูปเล่มธนาคาร"
                                                                                }
                                                                                if (image.inputName == 'addressBookImage') {
                                                                                    title = "รูปสำเนาทะเบียนบ้าน"
                                                                                }
                                                                                return (
                                                                                    <div className='col-3'>
                                                                                        <a data-fancybox href={image.imagePath ? image.imagePath : "/assets/images/no_image.png"}
                                                                                            data-caption={title}
                                                                                            style={{ display: 'block', marginBottom: 15, width: 100, height: 100, overflow: 'hidden', borderRadius: 1000 }}
                                                                                        >
                                                                                            <img src={image.imagePath ? image.imagePath : "/assets/images/no_image.png"} alt={"NOIMAGE"} style={{ maxWidth: '100%' }} />
                                                                                        </a>
                                                                                        <p style={{ textAlign: 'center' }}>{title}</p>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }

                                                    <button className='btn btn-default'
                                                        onClick={() => {
                                                            Helper.reactToDom(window.$('body'),
                                                                <ChangeProfilePasswordModal
                                                                    agent={agent}
                                                                    onSuccess={() => {
                                                                        // Helper.setCookie('user', "", 1);
                                                                        // window.location = '/';
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    >
                                                        เปลี่ยนรหัสผ่าน
                                                    </button>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </>
                                : null
                        }

                        {
                            !this.state.is_loading
                                && !this.state.data ?
                                <>
                                    <h2 className='text-center'>ไม่มีข้อมูล</h2>
                                </>
                                : null
                        }
                    </div>
                </section>
            </>
        )
    }
}


class ChangeProfilePasswordModal extends React.Component {
    async update(data) {
        console.log(data, this.props.agent.uid);
        if (data.newPassword != data.confirmNewPassword) {
            window.$(document).Toasts('create', {
                title: 'รหัสผ่านไม่ถูกต้อง',
                // body: errorMessage,
                icon: 'fas fa-times',
                autohide: true,
                delay: 10000,
                class: "bg-danger",
            });
            this.$submit.removeClass('loading');
        }

        const res = await agentService.changePassword(this.props.agent.uid, data.oldPassword, data.newPassword, data.confirmNewPassword);
        if (res.error) {
            // if error
            window.$(document).Toasts('create', {
                title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
                body: res.response.data.message,
                icon: 'fas fa-times',
                autohide: true,
                delay: 10000,
                class: "bg-danger",
            });
            this.$submit.removeClass('loading');
            return
        }
        // if success
        window.$(document).Toasts('create', {
            title: 'เปลี่ยนรหัสผ่านสำเร็จ',
            // body: 'ทำการลบข้อมูลสำเร็จ',
            icon: 'fas fa-check',
            autohide: true,
            delay: 3000,
            class: "bg-success",
        })
        if (this.props.onSuccess) {
            this.$submit.removeClass('loading');
            this.$modal.modal('hide');
            this.props.onSuccess()
        }
    }

    render() {
        let agent = this.props?.agent || {};
        return (
            <div className="modal fade"
                id="edit_bank"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        let submit_btn = this.$submit;
                        if (submit_btn.hasClass('loading')) { return false; }
                        submit_btn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลธนาคาร</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>รหัสผ่านเก่า</label>
                                <input type='password' name="oldPassword" className="form-control" required={true} />
                            </div>
                            <div className="form-group">
                                <label>รหัสผ่านใหม่</label>
                                <input type='password' name="newPassword" className="form-control" required={true} />
                            </div>
                            <div className="form-group">
                                <label>ยืนยันรหัสผ่านใหม่</label>
                                <input type='password' name="confirmNewPassword" className="form-control" required={true} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type={"button"} className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >ยกเลิก</button>
                            <button type={"submit"} className="btn btn-primary">บันทึก</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}