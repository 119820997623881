import React, { Component } from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../../Helper';
import { BanProviderUserForm } from './View';
import { db } from '../../firebase';
const axios = require('axios').default;

export default class List extends Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            is_absolute_loading: false,
            data: [],
            countAll: 0
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let ref = await db.collection('provider_users_data').where('agentUid', '==', this.props.agent.uid).get();
        let maids = [];
        let countAll = 0;
        if (!ref.empty) {
            for (const doc of ref.docs) {
                let _data = doc.data();
                _data.uid = doc.id;
                maids.push(_data)
            }
            countAll = ref.size;
        }
        this.setState({
            data: maids,
            is_loading: false,
            countAll: countAll,
        })
    }
    render() {
        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">แม่บ้าน</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item">แม่บ้าน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <div className="container-fluid">
                        <div className="card">
                            <form className="card-header "
                                style={{ display: 'flex', alignItems: 'center' }}
                                ref={(ref) => { this.$searchForm = window.$(ref) }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    let data = Helper.getFormInputObject(this.$searchForm);
                                    let url = Helper.getQueryStringLinkFromObject({
                                        page: 1,
                                        ...data
                                    })
                                    navigate(url)
                                }}
                            >
                                <div className='mb-0'>
                                    <h3 className="card-title" style={{ display: 'flex', alignItems: 'center' }}>รายชื่อแม่บ้าน</h3>
                                    <p className='mb-0'>จำนวน {this.state.countAll} คน</p>
                                </div>
                            </form>
                            <div className="card-body">
                                {
                                    this.state.is_absolute_loading ?
                                        <div className="absolute_loading">
                                            <div className="ui active inline loader small" ></div>
                                        </div>
                                        : null
                                }
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "20%", minWidth: 230 }}>ชื่อ</th>
                                            <th style={{ minWidth: 150 }}>ข้อมูลการติดต่อ</th>
                                            <th style={{ minWidth: 130 }}>วันที่สมัคร</th>
                                            <th style={{ minWidth: 130 }}>สถานะ App</th>
                                            <th style={{ minWidth: 95 }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length > 0 ?
                                                this.state.data.map((_data, _data_i) => {
                                                    console.log(_data)
                                                    return (
                                                        <tr key={_data_i}>
                                                            <td>
                                                                {_data.gender === "ชาย" ? <i className="fas fa-mars"></i> : null}
                                                                {_data.gender === "หญิง" ? <i className="fas fa-venus"></i> : null}
                                                                {' '}
                                                                {_data.prefix} {_data.firstName} {_data.lastName}<br />
                                                                <i className="fas fa-star" style={{ color: '#ffa200' }}></i> {_data.provider_user && _data.provider_user.starFloat !== undefined ? _data.provider_user.starFloat.toFixed(2) : '5.00'}

                                                            </td>
                                                            <td>
                                                                <i className="fas fa-phone"></i> {_data.phoneNumber}
                                                                {
                                                                    _data.lineID ?
                                                                        <div><i className="fab fa-line"></i> {_data.lineID}</div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {Helper.getDateThai(_data.createdAt, { is_show_time: true })}
                                                                </div>
                                                                {
                                                                    _data.lastActive ?
                                                                        <div><strong>ใช้งานล่าสุด :</strong> {Helper.getDateThai(_data.lastActive, { is_show_time: true })}</div>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className={"flex row"}>
                                                                    <div>

                                                                        {
                                                                            _data.status === 'waitForApprove' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: '#ffa200' }} /> รอการยืนยัน</>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === 'approved' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: 'blue' }} /> ยืนยันแล้ว</>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === 'avaliable' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: 'green' }} /> ใช้งานได้</>
                                                                                : null
                                                                        }
                                                                        {
                                                                            _data.status === 'ban' || _data.status == 'cancel' ?
                                                                                <><i className={"fas fa-circle"} style={{ color: 'red' }} /> ระงับการใช้งาน</>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                    <Link to={'/provider-users/' + _data.uid} type="button" className="btn btn-default">ดูข้อมูล</Link>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {
                                            !this.state.is_loading
                                                && this.state.data.length === 0 ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        ไม่มีข้อมูล
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.is_loading ?
                                                <tr >
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        <div className="ui active inline loader small" ></div>
                                                    </td>
                                                </tr>
                                                : null
                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export class ProvincesAndAmphursList extends React.Component {
    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        data.createAt = new Date().getTime();
                        if (this.props.mode === 'create') {
                            this.add(data);
                        }
                        if (this.props.mode === 'edit') {
                            this.update(data);
                        }
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                พื้นที่ให้บริการ
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            {
                                this.props.provinces.map((province, province_i) => {
                                    return (
                                        <React.Fragment key={province_i}>
                                            {
                                                province_i > 0 ?
                                                    <div className={"divider"} />
                                                    : null
                                            }
                                            <h5>{province.province}</h5>
                                            <ul className="tag-label" style={{ paddingLeft: 0 }}>
                                                {
                                                    province.amphoes.map((amphoe, i) => {
                                                        return (
                                                            <div key={i}>
                                                                <div>{amphoe.amphoe}</div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </ul>

                                        </React.Fragment>
                                    );
                                })
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export class AmphurList extends React.Component {
    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submitBtn.hasClass('loading')) { return; }
                        this.$submitBtn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        data.createAt = new Date().getTime();
                        if (this.props.mode === 'create') {
                            this.add(data);
                        }
                        if (this.props.mode === 'edit') {
                            this.update(data);
                        }
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                {
                                    this.props.province
                                }
                            </h4>
                        </div>
                        <div className="modal-body"
                            ref={(ref) => {
                                this.$searchForm = window.$(ref);
                            }}
                        >
                            <ul className="tag-label" style={{ paddingLeft: 0 }}>
                                {
                                    this.props.amphoe.map((amphoe) => {
                                        return (
                                            <div>
                                                <div>{amphoe}</div>
                                            </div>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
class ProvincesAndAmphursSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_province: false,
            selected_amphoe: false,
            db: [],
            is_loading: true,
        }
    }
    componentDidMount() {
        this.fetch();
    }
    async fetch() {
        let province = await axios.get(window.api_host + "/service-area").then(async (res) => {
            let _data = res.data.data.filter((resp) => {
                return resp.amphoe.length > 0
            });
            //    console.log(_data)
            return _data
        })
        this.setState({
            db: province,
            selected_province: this.props.province || province[0].provinceCode,
            selected_amphoe: this.props.amphoe || false,
            is_loading: false,
        })
    }
    getAmphoe() {
        let apm = [];
        this.state.db.forEach((db) => {
            if (db.provinceCode === this.state.selected_province) {
                db.amphoe.forEach((amph, amph_i) => {
                    apm.push({ name: amph, code: db.amphoesCode[amph_i] });
                })
            }
        });
        // if (this.state.selected_amphoe.length > 0) {
        //     apm = apm.filter((_apm) => {
        //         let count = 0;
        //         this.state.selected_amphoe.forEach((selected_amphoe) => {
        //             if (_apm.code === selected_amphoe.code) {
        //                 count = 1;
        //             }
        //         })
        //         return count === 0
        //     })
        // }
        apm.sort()
        return apm;
    }
    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$searchForm = window.$(ref);
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) { return; }
                        this.$submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$searchForm);
                        // console.log(data)
                        this.props.onSearch(data)
                        this.$modal.modal('hide')
                    }}
                    style={{ position: 'relative' }}
                >
                    {
                        this.state.is_loading ?
                            <div className="absolute_loading">
                                <div className="ui active inline loader small" ></div>
                            </div>
                            : null
                    }
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                ค้นหาจากพื้นที่ให้บริการ
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>จังหวัดที่ต้องการค้นหา</label>
                                <select className="form-control"
                                    name={"province"}
                                    value={this.state.selected_province}
                                    onChange={(e) => {
                                        this.setState({
                                            selected_province: e.target.value,
                                            selected_amphoe: [],
                                        })
                                    }}
                                >
                                    {
                                        this.state.db.map((item, item_i) => (
                                            <option value={item.provinceCode} key={item_i}>{item.province}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label>พื้นที่เขต</label>
                                {
                                    !this.state.selected_province ?
                                        <input type={"test"} value={"เลือกจังหวัด"} disabled={true} className={"form-control"} />
                                        : null
                                }
                                {
                                    this.state.selected_province ?
                                        <select className="form-control"
                                            name={"amphoe"}
                                            onChange={(e) => {
                                                // if (e.target.value === "" || !e.target.value) { return; }
                                                this.setState({
                                                    selected_amphoe: e.target.value,
                                                })
                                            }}
                                            value={this.state.selected_amphoe}
                                        >
                                            <option value="">ทั้งหมด</option>
                                            {
                                                this.getAmphoe().map((amp, amp_i) => (
                                                    <option value={amp.code} key={amp_i}
                                                    >{amp.name}</option>
                                                ))
                                            }
                                        </select>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">ยกเลิก</button>
                            <button type="submit" className="btn btn-primary">ค้นหา</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}