import React from "react";
import { Helper } from "../Helper";
import { agentBankService } from "../Services/agentBankService";
const axios = require('axios').default;
export class BankForm extends React.Component {
    constructor() {
        super();
        this.state = {
            bank_list: [
                "ธนาคารกรุงเทพ",
                "ธนาคารกสิกรไทย",
                "ธนาคารกรุงไทย",
                "ธนาคารทหารไทย",
                "ธนาคารไทยพาณิชย์",
                "ธนาคารกรุงศรีอยุธยา",
                "ธนาคารเกียรตินาคินภัทร",
                "ธนาคารซีไอเอ็มบีไทย",
                "ธนาคารทิสโก้",
                "ธนาคารธนชาต",
                "ธนาคารยูโอบี",
                "ธนาคารไทยเครดิตเพื่อรายย่อย",
                "ธนาคารแลนด์แอนด์ เฮ้าส์",
                "ธนาคารไอซีบีซี (ไทย)",
                "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
                "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
                "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
                "ธนาคารออมสิน",
                "ธนาคารอาคารสงเคราะห์",
                "ธนาคารอิสลามแห่งประเทศไทย"
            ]
        }
    }

    async update(data) {
        let agent = this.props?.agent || {};

        const res = await agentBankService.update({ uid: agent.uid, ...data });
        if (res.error) {
            window.$(document).Toasts('create', {
                title: 'เกิดข้อผิดพลาด',
                body: 'กรุณาลองใหม่อีกครั้ง',
                icon: 'fas fa-times',
                autohide: true,
                delay: 3000,
                class: "bg-danger",
            })
            this.$submit.removeClass('loading');
            return;
        }

        window.$(document).Toasts('create', {
            title: 'เพิ่มข้อมูลธนาคารสำเร็จ',
            icon: 'fas fa-check',
            autohide: true,
            delay: 3000,
            class: "bg-success",
        })
        if (this.props.onSuccess) {
            let response = { ...data } // 
            this.props.onSuccess(response);
        }
        this.$modal.modal('hide');
        this.$submit.removeClass('loading');
    }

    render() {
        let agent = this.props?.agent || {};
        return (
            <div className="modal fade"
                id="edit_bank"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                    this.$form = this.$modal.find('form');
                    this.$submit = this.$form.find('[type="submit"]');
                }}
            >
                <form className="modal-dialog" role="document"
                    onSubmit={(e) => {
                        e.preventDefault();
                        let submit_btn = this.$submit;
                        if (submit_btn.hasClass('loading')) { return false; }
                        submit_btn.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        this.update(data);
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">แก้ไขข้อมูลธนาคาร</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>ธนาคาร</label>
                                <select className="form-control" name={"bank"} defaultValue={agent.bank || ''} required={true}>
                                    <option value={""}>เลือกธนาคาร</option>
                                    {
                                        this.state.bank_list.map((bank, bank_i) => {
                                            return (
                                                <option key={bank_i} value={bank}>{bank}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label>สาขา</label>
                                <input name="bankBranch" defaultValue={agent.bankBranch || ""} className="form-control" required={true} />
                            </div>
                            <div className="form-group">
                                <label>ชื่อบัญชี</label>
                                <input name="bankAccountName" defaultValue={agent.bankAccountName || ""} className="form-control" required={true} />
                            </div>
                            <div className="form-group">
                                <label>เลขบัญชี</label>
                                <input name="bankAccountNo" defaultValue={agent.bankAccountNo || ""} className="form-control" required={true} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type={"button"} className="btn btn-default"
                                onClick={() => {
                                    this.$modal.modal('hide');
                                }}
                            >ยกเลิก</button>
                            <button type={"submit"} className="btn btn-primary">บันทึก</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}