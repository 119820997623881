import React from 'react';
import { db, storage, getProviderUserByProderviderUserDataUid } from './../firebase';
import { Link, navigate } from '@reach/router';
// import CustomDateInput from './../Components/CustomDateInput';
// import DatePicker from "react-datepicker";
import { Helper } from './../Helper';
import { agentService } from '../Services/agentService';

const axios = require('axios').default;
export default class ViewSalary extends React.Component {
    constructor(props) {
        super(props);
        const years = [];
        let i;
        for (i = 1980; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
        this.state = {
            user: false,
            is_loading: true,
            provider_id: props.provider_id,
            books: [],
            withdraw_wallets: [],
            deposit_wallets: [],
            select_withdraw: false,
            timeRange: Helper.getParameterByName('timeRange') ? Helper.getParameterByName('timeRange') : 'month',
            years: years,
            year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
            month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
            vat: 0,
        }
    }

    componentDidMount() {
        this.fetchUser();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                is_loading: true,
                timeRange: Helper.getParameterByName('timeRange') ? Helper.getParameterByName('timeRange') : 'month',
                year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
                month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
                select_withdraw: false,
            }, () => {
                this.fetchUser();
            })
        }
        if (this.props.location.search !== prevProps.location.search && !this.props.location.search) {
            this.setState({
                is_loading: true,
                page: 1,
                limit: 10,
                timeRange: Helper.getParameterByName('timeRange') ? Helper.getParameterByName('timeRange') : 'month',
                year: Helper.getParameterByName('year') ? Helper.getParameterByName('year') : parseFloat(new Date().getFullYear()),
                month: Helper.getParameterByName('month') ? Helper.getParameterByName('month') : parseFloat(new Date().getMonth()),
                select_withdraw: false,
            }, () => {
                this.fetchUser();
            })
        }
    }

    async fetchUser() {
        let user = false;
        let res = await axios.get(`${window.api_host_agent}/agents/${this.props.agent.uid}`);
    
        if (res.data.success) {
            user = res.data.agent
        }
        const resBooking = await agentService.get(user.uid, this.state.timeRange, this.state.month, this.state.year);

        let withdraw_wallets = resBooking.withdrawnData;
        let deposit_wallets = resBooking.depositData;
        this.setState({
            user: user,
            is_loading: false,
            is_deposit_loading: false,
            deposit_wallets: deposit_wallets,
            withdraw_wallets: withdraw_wallets,
        })
    }


    render() {

        let withdraw_wallets_total_vat = 0;
        let withdraw_wallets_total_price = 0;
        this.state.withdraw_wallets.map((withdraw_wallet) => {
            withdraw_wallets_total_vat += parseFloat((withdraw_wallet.price * this.state.vat) / 100);
            withdraw_wallets_total_price += parseFloat(withdraw_wallet.price);
            return true
        })
        withdraw_wallets_total_price = withdraw_wallets_total_price - withdraw_wallets_total_vat
        let deposit_total_price = 0;
        this.state.deposit_wallets.map((deposit_wallet) => {
            deposit_total_price += parseFloat(deposit_wallet.price);
            return true
        })

        return (
            <>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">รายละเอียดรายได้</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to="/profile">ข้อมูลส่วนตัว</Link></li>
                                    <li className="breadcrumb-item active">รายละเอียดรายได้</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"content"}>
                    <div className="container-fluid" >
                        {
                            this.state.is_loading
                                && !this.state.user ?
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <div className="ui active inline loader" ></div>
                                </div>
                                : null
                        }
                        {
                            this.state.user ?
                                <>
                                    <div className="card">
                                        <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div>
                                                <h3 className="card-title">
                                                    <Link to={"/profile"}>
                                                        {this.state.user.prefix} {this.state.user.name}
                                                    </Link>
                                                </h3>
                                                <div><label style={{ marginBottom: 0, marginTop: 5 }}>รายได้ปัจจุบัน : </label> {' '}
                                                    <span style={{ color: 'green' }}>
                                                        {
                                                            this.state.user
                                                                && this.state.user.wallet ?
                                                                Helper.numberFormat(this.state.user.wallet)
                                                                : 0
                                                        }
                                                    </span> บาท

                                                </div>

                                            </div>
                                            <div style={{ marginLeft: 'auto' }}>

                                                <form className="input-group input-group-sm"

                                                    ref={(ref) => { this.$searchForm = window.$(ref) }}
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        let data = Helper.getFormInputObject(this.$searchForm);
                                                        let url = Helper.getQueryStringLinkFromObject({
                                                            ...data
                                                        })
                                                        navigate(url)
                                                    }}
                                                >

                                                    <select className={"form-control form-control-sm"}
                                                        name={"timeRange"}
                                                        ref={(ref) => { this.$timeRange = window.$(ref) }}
                                                        onChange={(e) => {
                                                            this.$searchForm.find('[type="submit"]').trigger('click');
                                                        }}
                                                        defaultValue={this.state.timeRange}
                                                    >
                                                        <option value={"month"}>รายเดือน</option>
                                                        <option value={"year"}>รายปี</option>
                                                    </select>
                                                    {
                                                        this.state.timeRange === 'month' ?
                                                            <select className={"form-control form-control-sm"}
                                                                name={"month"}
                                                                ref={(ref) => { this.$month = window.$(ref) }}
                                                                onChange={(e) => {
                                                                    this.$searchForm.find('[type="submit"]').trigger('click');
                                                                }}
                                                                defaultValue={this.state.month}
                                                            >
                                                                {
                                                                    window.months_th.map((month, month_i) => (
                                                                        <option value={month_i} key={month_i}>{month}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            : null
                                                    }

                                                    {
                                                        this.state.timeRange === 'year'
                                                            || this.state.timeRange === 'month' ?
                                                            <select className={"form-control form-control-sm"}
                                                                name={"year"}
                                                                ref={(ref) => { this.$year = window.$(ref) }}
                                                                onChange={(e) => {
                                                                    this.$searchForm.find('[type="submit"]').trigger('click');
                                                                }}
                                                                defaultValue={this.state.year}
                                                            >
                                                                {
                                                                    this.state.years.map((years) => (
                                                                        <option value={years} key={years}>{years + 543}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            : null
                                                    }
                                                    <div className="input-group-append" >
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-sm-12 col-lg-6">
                                            <div className="card">
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h3 className="card-title">
                                                        รายการโอนเงิน
                                                    </h3>
                                                </div>
                                                <div className={"card-body"}>
                                                    <div className={"flex row"}>
                                                        <div><label>ยอดโอนทั้งหมด</label> : <span style={{ color: 'green' }}>{Helper.numberFormat(withdraw_wallets_total_price)}</span> บาท</div>

                                                        <div style={{ marginLeft: 'auto' }}>
                                                            {
                                                                withdraw_wallets_total_vat > 0 ?
                                                                    <><label>ยอดหัก ภาษี 3%</label> : {withdraw_wallets_total_vat}</>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>

                                                    {
                                                        this.state.is_loading ?
                                                            <div style={{ padding: '1rem', textAlign: 'center' }}>
                                                                <div className="ui active inline loader" ></div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        !this.state.is_loading && this.state.withdraw_wallets ?
                                                            this.state.withdraw_wallets.map((withdraw_wallet, withdraw_wallet_i) => {
                                                                
                                                                let all_images = [];
                                                                if (withdraw_wallet.salary_data && withdraw_wallet.salary_data.image_paths) {
                                                                    for (const image of withdraw_wallet.salary_data.image_paths) {
                                                                        all_images.push(image);
                                                                    }
                                                                }
                                                                if (withdraw_wallet.images) {
                                                                    for (const image of withdraw_wallet.images) {
                                                                        all_images.push(image);
                                                                    }
                                                                }
                                                                if (withdraw_wallet.bookSelected) {
                                                                    for (const book of withdraw_wallet.bookSelected) {
                                                                        if (book.images) {
                                                                            for (const image of book.images) {
                                                                                all_images.push(image);
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                return (
                                                                    <div className={this.state.select_withdraw && this.state.select_withdraw.salaryUid === withdraw_wallet.salaryUid ? "info-box bg-green with-hover" : "info-box bg-yellow with-hover"} key={withdraw_wallet_i}
                                                                    >
                                                                        <span className="info-box-icon" style={{ cursor: 'pointer' }}>
                                                                            <i className="fas fa-hand-holding-usd" style={{ fontSize: 40 }} />
                                                                        </span>

                                                                        <div className="info-box-content">
                                                                            <div className={"flex row"}>
                                                                                <div className={"col-sm-12 col-lg-5"} style={{ cursor: 'pointer' }}>
                                                                                    {
                                                                                        withdraw_wallet.salary
                                                                                            && withdraw_wallet.salary.title ?
                                                                                            <span className="info-box-text">{withdraw_wallet.salary.title}</span>
                                                                                            : null
                                                                                    }
                                                                                    <span className="info-box-text">{Helper.getDateThai(withdraw_wallet.salary && withdraw_wallet.salary.createdAt ? withdraw_wallet.salary.createdAt : withdraw_wallet.createdAt, { is_show_time: true })}</span>
                                                                                    <span className="info-box-text"><strong>ยอดโอน</strong> : {Helper.numberFormat(withdraw_wallet.price)}</span>
                                                                                    {
                                                                                        this.state.vat > 0 ?
                                                                                            <span className="info-box-text"><strong>ภาษี 3%</strong> : {Helper.numberFormat((withdraw_wallet.price * 3) / 100)}</span>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        withdraw_wallet.salary_data
                                                                                            && withdraw_wallet.salary_data.note ?
                                                                                            <span className="info-box-text"><strong>บันทึกเพิ่มเติม</strong> : {withdraw_wallet.salary_data.note}</span>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                                <div className={"col-sm-12 col-lg-5"} style={{ cursor: 'pointer' }} >
                                                                                    <span className="progress-description">
                                                                                        {
                                                                                            withdraw_wallet.providerBankName ?
                                                                                                <div>{withdraw_wallet.providerBankName}</div>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            withdraw_wallet.providerBankBranch ?
                                                                                                <div>{withdraw_wallet.providerBankBranch}</div>
                                                                                                : null
                                                                                        }
                                                                                        {
                                                                                            withdraw_wallet.providerBankAccountNo ?
                                                                                                <div>{withdraw_wallet.providerBankAccountNo}</div>
                                                                                                : null
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                {
                                                                                    all_images
                                                                                        && all_images.length > 0 ?
                                                                                        <div className={"col-sm-12 col-lg-2"} style={{ position: 'relative' }}>
                                                                                            <div className="promotion-delete" style={{ top: 0 }}>
                                                                                                <span className="badge badge-primary" >{all_images.length}</span>
                                                                                            </div>
                                                                                            <a href={all_images[0].imageURL} data-fancybox={'slip_' + withdraw_wallet_i}>
                                                                                                <div className="card" style={{ marginBottom: 0 }}>
                                                                                                    <div className="card-body" style={{ padding: '0.5rem' }}>
                                                                                                        <div className="transfer-frame">
                                                                                                            <img src={all_images[0].imageURL} alt="Slip" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </a>
                                                                                            {
                                                                                                all_images.map((img, img_i) => {
                                                                                                    if (img_i === 0) return null;
                                                                                                    return (
                                                                                                        <a href={img.imageURL} data-fancybox={'slip_' + withdraw_wallet_i} style={{ display: "none" }} key={img_i}>
                                                                                                            <div className="card" style={{ marginBottom: 0 }}>
                                                                                                                <div className="card-body" style={{ padding: '0.5rem' }}>
                                                                                                                    <div className="transfer-frame">
                                                                                                                        <img src={img.imageURL} alt="Slip" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </a>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            <div className='col-12'>
                                                                                <span className="info-box-text"><strong>วันที่บันทึก</strong> : {Helper.getDateThai(withdraw_wallet.createdAt, { is_show_time: true })}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  col-lg-6">
                                            <div className="card">
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h3 className="card-title">
                                                        รายได้จากการจอง
                                                    </h3>
                                                </div>
                                                <div className={"card-body"}>
                                                    <div className={"flex row"}>
                                                        <div><label>รายได้ทั้งหมด</label> : <span style={{ color: 'green' }}>{Helper.numberFormat(deposit_total_price)}</span> บาท</div>

                                                    </div>
                                                    {
                                                        this.state.is_loading ?
                                                            <div style={{ padding: '1rem', textAlign: 'center' }}>
                                                                <div className="ui active inline loader" ></div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.is_deposit_loading ?
                                                            <div className="absolute_loading">
                                                                <div className="ui active inline loader" ></div>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        !this.state.is_loading && this.state.deposit_wallets ?
                                                            this.state.deposit_wallets.map((deposit_wallet, deposit_wallet_i) => {
                                                                return (
                                                                    <div className="info-box bg-yellow" key={deposit_wallet_i}>
                                                                        <span className="info-box-icon">
                                                                            <i className="fas fa-wallet" style={{ fontSize: 40 }} />
                                                                        </span>

                                                                        <div className="info-box-content">
                                                                            <div className={"flex row"}>
                                                                                <div className={"col-sm-12 col-lg-10"}>
                                                                                    <span className="info-box-text">{Helper.getDateThai(deposit_wallet.createdAt, { is_show_time: true })}</span>
                                                                                    <span className="info-box-text"><strong>Booking</strong> : <Link to={"/book/" + deposit_wallet.bookUid} className="common-link">{deposit_wallet.bookNo || deposit_wallet.bookOn}</Link></span>
                                                                                    {
                                                                                        deposit_wallet.withdraw_wallet
                                                                                            && deposit_wallet.withdraw_wallet.salary ?
                                                                                            <span className="info-box-text">
                                                                                                <strong>รายการโอน </strong> : <Link to={"/salary-provider/" + deposit_wallet.withdraw_wallet.salaryUid + '?page=1&name=' + deposit_wallet.withdraw_wallet.salary_data.displayName} className="common-link">
                                                                                                    {deposit_wallet.withdraw_wallet.salary.title || 'รอบบิล'}
                                                                                                </Link>
                                                                                            </span>
                                                                                            : null
                                                                                    }
                                                                                    <span className="progress-description">
                                                                                        <label style={{ margin: 0 }}>ค่าบริการ </label> : {Helper.numberFormat(deposit_wallet.price)}
                                                                                        {
                                                                                            deposit_wallet.isPaid ?
                                                                                                <>
                                                                                                    <br />
                                                                                                    <span className="badge badge-success"><i className="fas fa-check" /> โอนแล้ว</span>
                                                                                                </>
                                                                                                : null
                                                                                        }
                                                                                    </span>
                                                                                </div>

                                                                                <div className={"col-sm-12 col-lg-2"} style={{ position: 'relative' }}>

                                                                                    {
                                                                                        deposit_wallet.images
                                                                                            && deposit_wallet.images.length > 0 ?
                                                                                            <>
                                                                                                <div className="promotion-delete" style={{ top: 0 }}>
                                                                                                    <span className="badge badge-primary" >{deposit_wallet.images.length}</span>
                                                                                                </div>
                                                                                                <a href={deposit_wallet.images[0].imageURL} data-fancybox={'slip_deposit_' + deposit_wallet_i}>
                                                                                                    <div className="card" style={{ marginBottom: 0 }}>
                                                                                                        <div className="card-body" style={{ padding: '0.5rem' }}>
                                                                                                            <div className="transfer-frame">
                                                                                                                <img src={deposit_wallet.images[0].imageURL} alt="Slip" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </a>
                                                                                                {
                                                                                                    deposit_wallet.images.map((img, img_i) => {
                                                                                                        if (img_i === 0) return null;
                                                                                                        return (
                                                                                                            <a href={img.imageURL} data-fancybox={'slip_deposit_' + deposit_wallet_i} style={{ display: "none" }} key={img_i}>
                                                                                                                <div className="card" style={{ marginBottom: 0 }}>
                                                                                                                    <div className="card-body" style={{ padding: '0.5rem' }}>
                                                                                                                        <div className="transfer-frame">
                                                                                                                            <img src={img.imageURL} alt="Slip" />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </a>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                </div>
            </>
        );
    }
}
