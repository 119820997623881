import React, { Component, createContext } from "react";
import { Helper } from "../Helper";

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  constructor() {
    super();
    this.state = {
      user: Helper.getCookie('user') && Helper.getCookie('user') !== '' ? JSON.parse(Helper.getCookie('user')) : false,
      loading: true,
    }
  }

  componentDidMount = async () => {
    if (!this.state.user 
      && window.location.pathname !== "/"
      && window.location.pathname.indexOf('/email-change-password') == -1
      && window.location.pathname.indexOf('/forgot-password') == -1) {
      window.location = '/'
    }
    this.setState({
      loading: false,
    })
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <div style={{ padding: 15, display: 'flex', alignItems: 'center', backgroundColor: '#f4f6f9' }}>
            <div className="ui active inline loader small" style={{ marginRight: 5 }}></div> <span style={{ fontSize: 18 }}>
              Loading ...
            </span>
          </div>
        </>
      )
    }
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
