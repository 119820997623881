import React, { useState, useContext } from "react";
import { auth } from "../firebase";
// import { UserContext } from "../providers/UserProvider";
import { Link, navigate } from "@reach/router";
import { Helper } from "../Helper";
import { agentService } from "../Services/agentService";

export default class PasswordReset extends React.Component {
  constructor() {
    super();
    this.state = {
      emailHasBeenSent: false,
      error: false
    }
  }
  async sendResetEmail(email) {
    console.log(email);
    // call api
    const res = await agentService.emailResetPassword(email);
    if (res.error) {
      let errorMessage = "ไม่สามารถส่งอีเมล์ได้ กรุณาลองใหม่อีกครั้ง";
      this.setState({
        error: errorMessage
      })
      return
    }

    // if success 
    Helper.messageTop({ message: 'ระบบทำการส่งรหัสผ่านใหม่ไปยังอีเมล์เรียบร้อยแล้ว, กรุณาอีเมล์', type_class: 'alert-success' });
    this.$submitBtn.removeClass('loading')
    // navigate('/')
    // if error 

  }
  render() {
    return (
      <div className={"hold-transition login-page login_background"}>
        <div>

          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
        </div>
        <div className="login-box" style={{ marginTop: '-15rem' }}>
          <div className="card">
            <div className="login-logo">
              <img src="assets/images/logo.png" alt="Beeclean Logo" style={{ maxHeight: 150 }} />
            </div>
            <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0, marginTop: -20 }}>AGENT</h3>
            <div className="card-body login-card-body">
              <p className="login-box-msg mb-3 pb-0">กรุณากรอกอีเมล</p>

              <form ref={(ref) => {
                this.$form = window.$(ref)
              }}
                onSubmit={(e) => {
                  e.preventDefault();
                  if (this.$submitBtn.hasClass('loading')) { return; }
                  this.$submitBtn.addClass('loading')
                  let data = {};
                  this.$form.serializeArray().map((item) => {
                    data[item.name] = item.value;
                    return true
                  })
                  this.sendResetEmail(data.email)
                }}
              >
                <div className="input-group mb-3">
                  <input type="email"
                    className="form-control"
                    placeholder="อีเมล"
                    name="email"
                    onChange={() => {
                      window.$('.alert.alert-danger').remove();
                    }}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <p className="login-box-msg p-0 mb-3">ระบบจะทำการส่งรหัสผ่านใหม่ไปทางอีเมล์ของท่าน</p>
                {
                  this.state.error ?
                    <div className="alert alert-danger d-flex justify-content-center">
                      {this.state.error}
                    </div>
                    : null
                }
                <button type="submit"
                  ref={(ref) => { this.$submitBtn = window.$(ref); }}
                  className="btn btn-primary btn-block mb-2"
                >ส่งอีเมล์</button>

                <div className="d-flex justify-content-center">
                  <Link
                    to="/"
                    className="my-2 text-blue-700 hover:text-blue-800 text-center block"
                  >
                    กลับ
                  </Link>
                </div>
              </form>


            </div>
          </div>
        </div>
      </div>
    )
  }
}