import React from 'react';
import { Link } from '@reach/router';
// const NavLink = props => (
//   <Link
//     {...props}
//     getProps={({ isCurrent }) => {
//       return {
//         className: isCurrent ? "item active" : "item"
//       };
//     }}
//   />
// );
export default class SideBar extends React.Component {
  componentDidMount() {
    window.$(document).ready(() => {
      window.$('[data-widget="treeview"]').Treeview('init')
    });
  }

  componentDidUpdate() {
  }

  currentLink = ({ isCurrent }) => {
    return {
      className: isCurrent ? "nav-link active" : "nav-link"
    };
  }

  render() {
    // console.log(this.props.user)
    return (
      <aside className="main-sidebar elevation-4 sidebar-dark-warning " >
        <Link to={"/dashboard"} className="brand-link navbar-warning" style={{ backgroundColor: '#48D4C9'}}>
          <img src={window.location.origin + "/assets/images/logo-horizontal.png"} alt="Beeclean Logo" style={{ maxWidth: '100%', maxHeight: 19 }} />
        </Link>
        <div className="sidebar ">
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column nav-legacy nav-compact nav-child-indent" data-widget="treeview" role="menu" data-accordion="false" id="custom-menuactive">
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link"
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent || window.location.pathname === "/" || window.location.pathname === "" ? "nav-link active" : "nav-link"
                    };
                  }}
                >
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>หน้าหลัก</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/books" className="nav-link"
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent || window.location.pathname === "/books" || window.location.pathname.indexOf(`/book/`) != -1 ? "nav-link active" : "nav-link"
                    };
                  }}
                >
                  <i className="nav-icon fas fa-clipboard-check"></i>
                  <p>รายการ Booking</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/maids" className="nav-link"
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent || window.location.pathname === "/maids" || window.location.pathname.indexOf(`/maid/`) != -1 ? "nav-link active" : "nav-link"
                    };
                  }}
                >
                  <i className="nav-icon fas fa-users"></i>
                  <p>แม่บ้าน</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/profile" className="nav-link"
                  getProps={({ isCurrent }) => {
                    return {
                      className: isCurrent || window.location.pathname === "/profile" || window.location.pathname.indexOf(`/profile/`) != -1 ? "nav-link active" : "nav-link"
                    };
                  }}
                >
                  <i className="nav-icon fas fa-user-secret"></i>
                  <p>ข้อมูลส่วนตัว</p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}